import React, { useState, useEffect } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import Toast from '../../../../components/Toast/Toast';

import Modal2 from "../../../../components/Modal/Modal";
import {
  deleteConjuntosAnalisis,
  getTiposConjuntosAnalisis,
  getConjuntosAnalisisPaginated
}
  from '../../../../api/Configuracion';

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]

const ConjuntoDeAnalisis = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [conjuntosAnalisis, setConjuntosAnalisis] = useState([]);
  const [conjuntoAnalisisId, setConjuntoAnalisisId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [tiposConjuntoAnalisis, setTiposConjuntoAnalisis] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);


  useEffect(() => {

    fetchConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm, "primera");
    getTiposConjuntosAnalisis().then(res => {
      setTiposConjuntoAnalisis(res.data.items.map(res => ({ value: res.id, label: res.desc_cana })))
    })
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchEstadosFinalesPaginated y searchTerm en la dependencia array

  const fetchConjuntosAnalisisPaginated = async (pagina, order, search, action) => {
    const res = await getConjuntosAnalisisPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setTimeout(() => {
        setSuccess(false);
      }, 1000)

    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setConjuntosAnalisis(res.data.items.map((res) => {
      return { 
        tipoId: res.tipocana.id, 
        tipo: res.tipocana.desc_tpcana, 
        id: res.id, codigo: res.codigo_cana, 
        descripcion: res.desc_cana, 
        habilitado: res.status 
      };
    }));
  }

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setConjuntoAnalisisId(valueRow.id);
      setModalStatus2(true);

    }
  }

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

 const handleDelete = (unidadId) => {
    setIsLoading(true);
    setModalStatus2(false);
    deleteConjuntosAnalisis(unidadId)
      .then(() => {
        setIsLoading(false);
        setConjuntoAnalisisId("");
        setSuccess(true);
        setMsjToast("Conjunto de Análisis eliminado con éxito.");
        setKey(key + 1);
        
        const paginaActualTemp = conjuntosAnalisis.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);

        return fetchConjuntosAnalisisPaginated(
          paginaActualTemp, 
          orderBy, 
          searchTerm,
          "delete"
        );
      })
      .catch(err => console.error("Error al eliminar el conjunto de análisis:", err));
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

   const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchConjuntosAnalisisPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      <div className="toast" >
        {success ? <Toast key={key} title={msjToast} close={() => setSuccess(false)} /> : null}
        {error ? <Toast key={key} type={"error"} title={msjToast} close={() => setError(false)} /> : null}
      </div>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"conjuntoAnalisis"}
         onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchConjuntosAnalisisPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          } else {
            fetchConjuntosAnalisisPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false);
            setMsjToast("");
          }, 1000);
          setModalStatus(false);
        }}        
        // onClose={() => {
        //   setModalStatus(false);
        //   getConjuntosAnalisisPaginated(paginaActual, orderBy, searchTerm).then((res) => {
        //     setPageCount(res.data.totalPages);
        //     setItemsCount(res.data.totalItems);
        //     setConjuntosAnalisis(res.data.items.map((res) => {
        //       return ({ tipoId: res.tipocana.id, tipo: res.tipocana.desc_tpcana, id: res.id, codigo: res.codigo_cana, descripcion: res.desc_cana, habilitado: res.status });
        //     }));
        //   })
        // }}
    


      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Conjunto de Análisis"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nuevo conjunto de análisis"}
        onClickButton={() => { handleClickButtonHeader() }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={conjuntosAnalisis}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => { handleOrder(value) }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDelete(conjuntoAnalisisId);
        }}
      ></Modal2>
    </Styled>
  )
}

export default ConjuntoDeAnalisis

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    
  }
`