import React, { useRef, useState, useEffect } from "react";
import CustomButton from "../Button/CustomButton.tsx";
import styled from "styled-components";

import { useDropzone } from "react-dropzone";
import { Dialog, DialogContent, IconButton, DialogTitle } from "@mui/material";
import { UploadFile, DeleteOutlined, Close } from "@mui/icons-material";
import { colors } from "../../assets/styles/theme.ts";

const DropZone = (props) => {
  //const [imageUrls, setImageUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const inputRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      const validFiles = acceptedFiles.filter((file) => file.size <= 3 * 1024 * 1024);
      if (validFiles.length < acceptedFiles.length) {
        alert("Máximo hasta 3MB por imagen");
      }

      // esto lo convierte a base 64
      const newImageUrls = validFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            const imageType = base64String.match(/^data:(image\/\w+);base64,/)[1];
            const cleanBase64String = base64String.replace(/^data:image\/\w+;base64,/, '');
            resolve({ imagen_mue_base64: cleanBase64String, muestra_id: props.muestraId, content_type: imageType});
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImageUrls).then((base64Images) => {
        //setImageUrls((prevImages) => [...prevImages, ...base64Images]);
        if (props.onUploadImgs) {
          props.onUploadImgs([...props.images, ...base64Images]);
        }
      });
    },
  });
  
  /*useEffect(() => {
    if (props.images) {
      const initialImages = props.images?.map((res) => ({
        archivoId: res.archivoId ? res.archivoId : null,
        imageUrl: res.imagen_mue_base64 ? `data:image/png;base64,${res.imagen_mue_base64}` : null,
        muestra_id: res.muestra_id ? res.muestra_id : null
      }));
      setImageUrls(initialImages);
    }
  }, [props.images]);*/

  /*useEffect(() => {
    console.log(props.images);
  }, [props.images]);*/

  const removeImage = (index, img) => {
    const updatedImages = props.images.filter((_, i) => i !== index);
    //setImageUrls(updatedImages);
    if (props.onFileDelete) {
      props.onFileDelete(index, img);
    }
  };

  const openDialog = () => {
    inputRef.current.click();
  };
  const handleClickImage = (index,img) => {
    console.log(img)
    setSelectedImage(img);
    if (props.selectedImg) {
      props.selectedImg(index, img);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Styled>
      <div {...getRootProps({ className: "dropzone" })}>
        {props.images?.length > 0 && (
          <div className="divButton">
            <input {...getInputProps()} ref={inputRef} />
            <UploadFile onClick={openDialog} className="iconUpload" />
          </div>
        )}
        {props.images?.length > 0 ? (
          <div className="divFiles">
            {props.images.map((img, index) => (
              <div key={index} className="divFile">
                <div
                  className="divImg"
                  onClick={() => handleClickImage(index, img)}
                >
                  <img src={`data:${img.content_type};base64,${img.imagen_mue_base64}`} alt={`Uploaded ${index}`} />
                </div>
                <div className="divIcon">
                  <DeleteOutlined
                    className="deleteIcon"
                    onClick={() => removeImage(index, img)}
                    sx={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <input {...getInputProps()} ref={inputRef} />
            <div className="divIcon">
              <UploadFile fontSize="medium" />
            </div>
            <div className="divButton">
              <CustomButton
                label="Click para subir archivo"
                onClick={openDialog}
                className="button"
                size="small"
              />
              <p className="label">o arrastre aquí el archivo</p>
            </div>

            <div className="divSub">
              <p className="sub">PNG, JPG (max. 3MB)</p>
            </div>
          </>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" PaperProps={{
        style: {
          border: `4px solid ${colors.turquoise}`,
          boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.25)",
          padding: "5px",
        },
      }}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 20px",
          }}
        >
          <IconButton onClick={handleClose} edge="end">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="ModalDialogContent" >
          {selectedImage && (
            <img
              src={`data:${selectedImage.content_type};base64,${selectedImage.imagen_mue_base64}`}
              alt="Full Size"
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Styled>
  );
};
export default DropZone;

const Styled = styled.div`
  .title {
    position: absolute;
    z-index: 1;
    background: white;
    top: 48px;
    left: 30px;
    color: #545454;
    font-family: Inter;
    font-weight: bold;
    font-size: 12px;
  }
  .iconUpload {
    color: ${colors.turquoise};
    background: ${colors.lightGray};
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: ${colors.turquoise};
      color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  .dropzone {
    border: 2px dashed #afccd5;
    padding: 20px 0px;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .divIcon {
    svg {
      color: #2196f3;
    }
  }
  .divButton {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      text-decoration: underline;
      color: #2196f3;
    }
    .label {
      font-family: Inter;
      font-weight: bold;
      margin: 0px;
      font-size: 12px;
    }
  }
  .divSub {
    .sub {
      color: #b0b0b0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
  .divFiles {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .divFile {
      width: 120px;
      height: 120px;
      padding: 10px;
      .divImg {
        width: 100%;
        height: 100%;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: none;
        }
      }
    }
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
  }
  @media (max-width: 768px) {
    .dropzone {
      padding: 10px;
    }
    .divFiles {
      align-items: flex-start;
      gap: 0px;
      .divFile {
        display: flex;
        align-items: center;
      }
    }
  }
`;
