import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardNumber from "../../../../CompDiagMasivos/CardNumber.tsx";
import Warning from "../../../../../assets/Icons/Buttons/Muestras/warning.svg";
import BoxResult from "../../../../BoxResult/BoxResult.tsx";
import { RenderImgStyled } from '../../../../Tables/TablesStyled.js';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DiagMasivoModal = (props) => {
  const [formCompleto, setFormCompleto] = useState(false);

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
    mensajePredefinido: '',
    diagnostico: '',
    sugerencias: '',
    estadoFinal: '',
    fechaAnalisis: ''
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo', 'mensajePredefinido',
      'diagnostico',
      'sugerencias',
      'estadoFinal',
      'fechaAnalisis'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Diagnóstico Masivo"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }

  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={!formCompleto}
        />

      </>
    );
  };


  return (
    <StyledModalcomponentMuestras sx={{
      width: "95%",
      padding: "20px",
      '@media (max-width: 768px)': {
        padding: "10px",
        width: "90%",
        height: "90%",
      },
    }}>
      <div className="diagMasivo">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divBody">
            <div className="aCol">
              <Input
                label="Cliente"
                className="input"
                name="cliente"
                value={form.cliente}
              />
              <Input
                label="Orden de Trabajo"
                className="input"
                name="ordenTrabajo"
                value={form.ordenTrabajo}
              />

              <div className="divEstadoFinal">
                <Input
                  reactSelect
                  // options={clientes}
                  // value={selectedOptioncli}
                  // onChange={(e) => {
                  //   handleChangeCliente(e, "cliente");
                  // }}
                  placeholder={"Estado Final"}
                />

                <div className="divImagenes">
                  <p className="label">Haga click sobre el Estado Final de la muestra</p>
                </div>
              </div>
              <div className="divFechaAnalisis">

                <div className="divDateSwitch"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker", "DatePicker"]}
                    >
                      <DatePicker
                        className="inputDate"
                        label="Fecha de Análisis"
                        // disabled={ver}
                        // defaultValue={form}
                        // value={fechaseleccionada}
                        slotProps={
                          <Input
                            className="input inputDate"
                            variant="outlined"
                            size={"medium"}
                          />
                        }
                      // onChange={(e) => {
                      //   handleChangeCalendar(e, "fecha");
                      // }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormControlLabel
                    control={<Switch name="reasignar" onChange={handleChange} style={{ margin: "0px" }} />}
                    label="Reasignar estados"
                  />
                </div>

                <div className="divCard">
                  <RenderImgStyled img={Warning} style={{ height: "25px" }} />
                  <p className="label">ATENCIÓN! Al seleccionar la opción “Reasignar estados”, se perderán los estados anteriores</p>
                </div>


              </div>
            </div>
            <div className="bCol">
              <Input
                reactSelect
                // options={clientes}
                // value={selectedOptioncli}
                // onChange={(e) => {
                //   handleChangeCliente(e, "cliente");
                // }}
                placeholder={"Mensaje Predefinido"}
              />
              <Input
                id="outlined-multiline-static"
                label="Diagnóstico"
                multiline
                rows={4}
                defaultValue="Default Value"
                className="input"
                name="diagnostico"
                onChange={handleChange}
                value={form.diagnostico}
              />
              <Input
                id="outlined-multiline-static"
                label="Sugerencias"
                multiline
                rows={4}
                defaultValue="Default Value"
                className="input"
                name="sugerencias"
                onChange={handleChange}
                value={form.sugerencias}
              />

            </div>
          </div>

          <div className="cCol">
            <div className='divCardNumber'>
              <CardNumber label="Total de Muestras" value={12} />
              <CardNumber label="Con estado asignado" value={2} />
              <CardNumber label="Cantidad a modificar" value={12} active={true} onClick={() => alert()} />
            </div>
            <div className="divButtons">
              {renderButtons()}
            </div>
          </div>
          <div className="divBoxResult">
            <BoxResult
              backgroundColor='green'
              label="hola"
              sub="chau"
              icon />
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default DiagMasivoModal;