import React, { useEffect, useState, useReducer } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import { StyledModalComponentTable } from "../../../Styled.jsx";
import { Switch, Divider, FormControlLabel } from "@mui/material";
import Tables from "../../../../Tables/Tables.jsx";
import { RenderImgStyled } from "../../../../../Pages/Task/Items/SuperAdminStyled.js";
import More from "../../../../../assets/Icons/Buttons/More.svg";
import { buttonsDefault, buttonsDefaultver } from "../../../../ButtonsTable/ButtonsTable.js";
import Toast from "../../../../Toast/Toast";
import Modal from "../../../Modal.jsx";
import Modal2 from "../../../Modal.jsx";
import Preloader from '../../../../Preloader/Preloader.jsx';

import {
  getProtocolosPaginated,
  getTiposConjuntosAnalisisOptions,
  updateConjuntosAnalisis,
  createConjuntosAnalisis,
  deleteProtocolos,

} from "../../../../../api/Configuracion";

const columns = [
  { id: "orden", label: "Orden" },
  { id: "analisis", label: "Análisis" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];


const CEAnalisis = (props) => {

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [tipoUnidades, setTipoUnidades] = useState([]);
  const [protocolos, setProtocolos] = useState([]);
  const [protocolosId, setProtocolosId] = useState("");
  const [estadosCargados, setEstadosCargados] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [key, setKey] = useState(1);

  const propsUnidades = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsUnidades(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(5);

  const [isLoading, setIsLoading] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [conjuntosAnalisisId, setConjuntosAnalisisId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [paginaActual, setPaginaActual] = useState(1);

  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? conjuntosAnalisisId,
      codigo: datos.codigo ?? "",
      descripcion: datos.descripcion ?? "",
      tipoUnidad: datos.tipoId ?? "",
      habilitado: editar || ver
        ? datos.habilitado === "ACTIVE" || datos.habilitado === "SI"
        : true
    }
  );

  useEffect(() => {
    const fetchTiposCAnalisis = async () => {
      const res = await getTiposConjuntosAnalisisOptions();
      const unidades = res?.data?.map(item => ({
        value: item.id,
        label: item.desc_tpcana,
      }));
      setTipoUnidades(unidades);
      if (datos) {
        const matchOption = unidades.find(option => option.value === datos.tipoId);
        if (matchOption) {
          setSelectedOption(matchOption);
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      } else if (unidades.length > 0 && editar) {
        setSelectedOption(unidades[0]);
      }
    };
    fetchTiposCAnalisis();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setForm({ type: 'SET_FIELD', field: "tipoUnidades", value: selectedOption.value });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (editar || ver) {
      getProtocolosPaginated(form.id, paginaActual, 20, orderBy).then(
        (res) => {
          let protocolos = [];
          if ((form && form.id) || conjuntosAnalisisId !== "") {
            protocolos = res.data.items
              .map((res) => {
                return {
                  id: res.id,
                  analisis: res.analisis_id.description,
                  orden: res.orden_pro,
                  habilitado: res.status,
                  conjuntoAnalisis: res.conjunto_id.id,
                };
              });
          } else {
            protocolos = [

            ];
          }
          setProtocolos(protocolos);
          setPageCount(res.data.totalPages);
          setItemsCount(res.data.totalItems);
        }
      );
    }
  }, [editar, ver, orderBy])

  useEffect(() => {
    const id = form?.id || conjuntosAnalisisId;
    if (id) {
      getProtocolosPaginated(id, paginaActual, 20, orderBy).then((res) => {
        const protocolos = res.data.items.map((res) => ({
          id: res.id,
          analisis: res.analisis_id.description,
          orden: res.orden_pro,
          habilitado: res.status,
          conjuntoAnalisis: res.conjunto_id.id,
        }));

        setProtocolos(protocolos);
        setPageCount(res.data.totalPages);
        setItemsCount(res.data.totalItems);
      });
    }
  }, [paginaActual, orderBy, form?.id, conjuntosAnalisisId]);

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);

    //El metodo siguiente desabilita el boton de guardar 
    //si las longitudes de los campos no son validas.

    // const campoValido = campo => {
    //    if (campo === 'codigo') {
    //     return form[campo].length <= 20 && form[campo] !== '';
    //   }
    //   if (campo === 'descripcion') {
    //     return form[campo].length <= 200 && form[campo] !== '';
    //   }
    //   return form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    // };

    const camposaValidar = ["codigo", "descripcion", "tipoUnidad"];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };

  const onClickClose = () => {
    if (ver) {
      props.onClick({
        msj: "",
        status: "",
      });
    } else {
      if (success) {
        props.onClick({
          msj: msjToast,
          status: "success",
        })
      } else if (error) {
        props.onClick({
          msj: msjToast,
          status: "error",
        })
      } else {
        props.onClick({
          msj: "",
          status: "",
        });
      }
    }
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
          //onClick={props.onClick}
          onClick={onClickClose}
        />
        {!estadosCargados && !ver && <CustomButton
          label="Confirmar"
          variant="contained"
          className="buttonColorDefault btn"
          type="submit"
          disabled={!formCompleto}
        // disabled={!editar ? !formCompleto : false} 
        />}

        {estadosCargados && (
          <CustomButton
            label="Cerrar"
            variant="contained"
            className="buttonColorDefault btn"
            disabled={false}
            onClick={() => {
              props.onClick({
                msj: "",
                status: "",
              });
            }}
          // onClick={() => {
          //   if (success) {
          //     props.onClick({
          //       msj: "",
          //       status: "",
          //     });
          //   }
          // }}
          />
        )}
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.codigo.length > 20) {
      setMsjToast("El codigo es muy largo.");
      setError(true);
      return;
    } else if (form.descripcion.length > 200) {
      setMsjToast("La descripcion es muy larga.");
      setError(true);
      return;
    }

    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_cana: form.codigo,
      desc_cana: form.descripcion,
      tipocana_id: form.tipoUnidad.toString(),
      created_by: "dasfdasd",
      created_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };
    if (editar) {
      updateConjuntosAnalisis(formattedData).then((res) => {
        setSuccess(true);
        setIsLoading(false);
        setKey(key + 1);
        setMsjToast("Conjunto de análisis modificado con exito");
        // setTimeout(() => {
        //   props.onClick();
        // }, 100);
      });
    } else {
      createConjuntosAnalisis(formattedData).then((res) => {
        setIsLoading(false);
        setEstadosCargados(true);
        setKey(key + 1);
        setConjuntosAnalisisId(res.data);
        setDatosModal({ datos: { conjuntoAnalisis: res.data } });
        setSuccess(true);
        setMsjToast("Conjunto de Análisis creado con exito");
      });
    }
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver conjunto de análisis"
      : editar
        ? "Editar conjunto de análisis"
        : "Crear conjunto de análisis";
    return titulo;
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setProtocolosId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleDeleteProtocolo = async (id) => {

    await deleteProtocolos(id)
      .then(() => {
        setSuccess(true);
        setProtocolosId("");
        const conjuntoId = form?.id || conjuntosAnalisisId;
        return getProtocolosPaginated(conjuntoId, paginaActual, 20).then(
          (res) => {
            let protocolos = [];
            if (form && form.id) {
              protocolos = res.data.items
                .filter((res) => {
                  return res.conjunto_id.id === form.id;
                })
                .map((res) => {
                  return {
                    id: res.id,
                    analisis: res.analisis_id.description,
                    orden: res.orden_pro,
                    habilitado: res.status,
                    conjuntoAnalisis: res.conjunto_id.id,
                  };
                });
            } else {
              protocolos = [
                // { id: "", analisis: "", orden: "", habilitado: "" },
              ];
            }
            setMsjToast("Análisis eliminado con exito");
            setTimeout(() => {
              setSuccess(false);
              setProtocolos(protocolos);
            }, 1500);
            setPageCount(res.data.totalPages);
            setItemsCount(res.data.totalItems);
          }
        );
      })
      .catch((err) => console.error("Error al eliminar el Metodo:", err));
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  return (
    <StyledModalComponentTable style={{ height: "90%" }}>

      <Preloader title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading} />

      <div className="toast" style={{ zIndex: "1000", left: "0px" }}>
        {success ? (
          <Toast key={key} title={msjToast} timeClose={3000}/>
        ) : null}
        {error ? (
          <Toast
            key={key}
            type={"error"}
            title={msjToast}
            timeClose={3000}
          />
        ) : null}
      </div>

      <div className="CEAnalisis">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>

        <div className="divBody">
          <div className="divInputsItem">
            <form className="formulario" onSubmit={handleSubmit}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={ver}
                    name="habilitado"
                    checked={form.habilitado}
                    onChange={handleChange}
                  />
                }
                label="Habilitar"
              />
              <Input
                value={form.codigo}
                label="Código"
                className="input inputCodigo"
                variant="outlined"
                name="codigo"
                onChange={handleChange}
                disabled={ver || estadosCargados}
              />
              <Input
                value={form.descripcion}
                label="Descripción"
                className="input inputDesc"
                variant="outlined"
                name="descripcion"
                onChange={handleChange}
                disabled={ver || estadosCargados}
              />
              <Input
                reactSelect
                options={tipoUnidades}
                value={selectedOption}
                onChange={(e) => { handleChange(e, "tipoUnidad") }}
                isDisabled={ver || estadosCargados}

                placeholder={ver ? "Tipo" : "Selecciona el tipo"}
              />
              <div className="divButtonsItem">{renderButtons()}</div>
            </form>
          </div>
          <Divider orientation="vertical" className="hr-vertical" />
          <div className="divTableCEAnilisis">
            <div className="divHeadTable">
              <p className="label">Protocolo del conjunto de análisis</p>

              <CustomButton
                disabled={conjuntosAnalisisId === "" && !editar}
                label="Nuevo análisis de conjunto"
                variant="contained"
                className="buttonHeaderTable"
                onClick={() => {
                  setModalStatus(true);
                  setConjuntosAnalisisId(conjuntosAnalisisId ? conjuntosAnalisisId : props.datos.datos.id);
                  setDatosModal({
                    datos: { conjuntoAnalisis: conjuntosAnalisisId ? conjuntosAnalisisId : props.datos.datos.id },
                  });
                }}
                startIcon={<RenderImgStyled img={More} />}
              />
            </div>
            {conjuntosAnalisisId === "" && ver ? <Tables
              typeTable={"prop"}
              columns={columns}
              rows={protocolos}
              cRows={20}
              pagination={true}
              totalRows={itemsCount}
              buttons={buttonsDefaultver}
              onClickSwitch={(value) => { handleOrder(value) }}
              onClickButtonTable={(value, valuesRow) => {
                if (conjuntosAnalisisId === "") {
                  valuesRow.conjuntoAnalisis = props.datos.datos.id
                } else {
                  valuesRow.conjuntoAnalisis = conjuntosAnalisisId
                }
                handleClickButonTable(value, valuesRow);
              }}
            /> : (
              <Tables
                typeTable={"prop"}
                columns={columns}
                rows={protocolos}
                cRows={20}
                pagination={protocolos?.length > 0 && true}
                totalRows={itemsCount}
                actualpage={paginaActual}
                page={(e) => setPaginaActual(e + 1)}
                pages={pageCount}
                buttons={buttonsDefault}
                onClickSwitch={(value) => { handleOrder(value) }}
                onClickButtonTable={(value, valuesRow) => {
                  if (conjuntosAnalisisId === "") {
                    valuesRow.conjuntoAnalisis = props.datos.datos.id
                  } else {
                    valuesRow.conjuntoAnalisis = conjuntosAnalisisId
                  }
                  handleClickButonTable(value, valuesRow);
                }}
              />
            )}
            <Modal2
              type="eliminar"
              isOpen={statusModal2}
              onClose={() => setModalStatus2(false)}
              onClickSuccess={() => {
                setModalStatus2(false);
                handleDeleteProtocolo(protocolosId);
              }}
            />
          </div>
        </div>
        <Modal
          modalNav
          datos={{ ...datosModal, descripcion: datos.descripcion ?? form.descripcion, protocolos: protocolos }}
          isOpen={statusModal}
          onClose={() => {
            setModalStatus(false);
            if (conjuntosAnalisisId || form.id) {
              getProtocolosPaginated((conjuntosAnalisisId ? conjuntosAnalisisId : form.id), paginaActual, 20, orderBy).then(
                (res) => {
                  let protocolos = [];
                  if ((form && form.id) || conjuntosAnalisisId !== "") {
                    protocolos = res.data.items
                      .filter((res) => {
                        return res.conjunto_id.id === form.id || res.conjunto_id.id === conjuntosAnalisisId;
                      })
                      .map((res) => {
                        return {
                          id: res.id,
                          analisis: res.analisis_id.description,
                          orden: res.orden_pro,
                          habilitado: res.status,
                          conjuntoAnalisis: res.conjunto_id.id,
                        };
                      });
                  } else {

                    protocolos = [
                    ];
                  }
                  setProtocolos(protocolos);
                  setPageCount(res.data.totalPages);
                  setItemsCount(res.data.totalItems);
                }
              );
            }
          }}
          type={"itemConjuntoAnalisis"}
        />
      </div>
    </StyledModalComponentTable>
  );
};

export default CEAnalisis;
