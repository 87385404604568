import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Toast from "../../../../Toast/Toast.jsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import { StyledModalcomponent } from "../../../Styled.jsx";
import {
  createMetodo,
  updateMetodo,
  getMetodos,
} from "../../../../../api/Configuracion";

const Metodos = (props) => {
  const propsMetodos = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsMetodos(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [msjToast, setMsjToast] = useState("");
  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      descripcion: datos.descripcion ?? "",
      habilitado: editar || ver
        ? datos.habilitado === "ACTIVE" || datos.habilitado === "SI"
        : true
    }
  );

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);

    const camposaValidar = ["codigo", "descripcion"];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (ver) {
      return;
    }
    const fieldValue = name === "habilitado" ? checked : value;
    setForm({ type: "SET_FIELD", field: name, value: fieldValue });
  };

  const titulo = () => {
    const titulo = ver
      ? "Ver métodos"
      : editar
        ? "Editar métodos"
        : "Crear o editar métodos";
    return titulo;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.codigo.length > 20) {
      setMsjToast("El codigo es muy largo.");
      setError(true);
      return;
    }else if (form.descripcion.length > 200) {
      setMsjToast("La descripcion es muy larga.");
      setError(true);
      return;
    }

    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_met: form.codigo,
      desc_met: form.descripcion,
      tipo_met_id: "1",
      created_by: "dasfdasd",
      created_date: new Date(),
      last_modified_by: "1",
      last_modified_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };

    if (editar) {
      updateMetodo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Método modificado con éxito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo editar el Método.",
            status: "error",
          });
        });
    } else {
      createMetodo(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({ msj: "Método creado con exito.", status: "success" });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo crear el Método.",
            status: "error",
          });
        });
    }
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto}
          />
        )}
      </>
    );
  };
  return (
    <StyledModalcomponent>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {" "}
        {success ? (
          <Toast title={msjToast} timeClose={3000} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            timeClose={3000}
            close={() => setError(false)}
          />
        ) : null}
      </div>

      <div className="metodos">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver}
              />
              <FormControlLabel
                control={
                  <Switch
                    name="habilitado"
                    disabled={ver}
                    checked={form.habilitado}
                    onChange={handleChange}
                  />
                }
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver}
            />
            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledModalcomponent>
  );
};

export default Metodos;
