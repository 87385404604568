import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';
import { getClienteOptions } from '../../../../../api/Clientes.js';
import { getAllAnalisisOptionsByOrden, getAllOrdenByCliente, getResultadoOptionsByAnalisis, updateResultadosValor } from '../../../../../api/Muestras.js';
import BoxResult from "../../../../BoxResult/BoxResult.tsx";

const CargaManualResultadosModal = (props) => {
  // const propsImportacionResultados = (datosUnidades) => {

  //   return {}
  // }
  // const {} = propsImportacionResultados(props.datos);
  const navigate = useNavigate();
  /*const [formCompleto, setFormCompleto] = useState(false);
  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
    analisis: '',
    nroMuestra: '',
    valorResultado: '',
  });*/

  /*useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo', 'analisis', 'nroMuestra', 'valorResultado'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);*/

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const [ordenes, setOrdenes] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [isLoadingOrdenes, setIsLoadingOrdenes] = useState(false);

  const [analisis, setAnalisis] = useState([]);
  const [selectedAnalisis, setSelectedAnalisis] = useState(null);
  const [isLoadingAnalisis, setIsLoadingAnalisis] = useState(false);

  const [resultados, setResultados] = useState([]);
  const [resultadosEdit, setResultadosEdit] = useState([]);  
  const [selectedResultadoIndex, setSelectedResultadoIndex] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingResultados, setIsLoadingResultados] = useState(false);

  useEffect(() => {
    const fetchClientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);
    };

    fetchClientes();
  }, []);



  const fetchOrdenes = async (e) => {
    setAnalisis([]);
    setSelectedAnalisis(null);
    setOrdenes([]);
    setSelectedOrden(null);
    if (e === null){
    } else {
      setIsLoadingOrdenes(true);
      const res = await getAllOrdenByCliente(e.value);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_otra,
      }));
      setOrdenes(unidades);
      setIsLoadingOrdenes(false);
    }
  };

  const fetchAnalisis = async (e) => {
    setAnalisis([]);
    setSelectedAnalisis(null);
    if (e === null){
    } else {
      setIsLoadingAnalisis(true);
      const res = await getAllAnalisisOptionsByOrden(e.value);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.description,
      }));

      const uniqueUnidades = unidades.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );

      setAnalisis(uniqueUnidades);
      setIsLoadingAnalisis(false);
    }
  };

  const fetchResultados = async (e) => {
    if (e === null){
      setSelectedAnalisis(null);
    } else {
      setIsLoadingResultados(true);
      const res = await getResultadoOptionsByAnalisis(e.value, selectedOrden.value);
      setResultados(res?.data);
      setResultadosEdit(res?.data);
      if(res.data.length > 0) setSelectedResultadoIndex((item, index) => res.data.findIndex(item => item.valor_res === "" || item.valor_res === null) === -1 ? 0 : res.data.findIndex(item => item.valor_res === "" || item.valor_res === null));
      setIsLoadingResultados(false);
    }
  };

  const handleChangeResultado = (e) => {
    const { name, value } = e.target;
    
    setResultadosEdit(() => {
      const updatedResultados = [...resultadosEdit];
      const selectedResultado = { ...updatedResultados[selectedResultadoIndex] };
      selectedResultado.valor_res = value;
      updatedResultados[selectedResultadoIndex] = selectedResultado;
      return updatedResultados;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Carga Manual de Resultados"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(-1);
  };
  const handleClickConfirmar = async () => {
    setIsUpdating(true);

    // Hago la actualizacion en back y si sale bien la hago en front
    const resultadoToUpdate = {
      "resultados": [
        {
          "id": resultadosEdit[selectedResultadoIndex].id,
          "valor_res": resultadosEdit[selectedResultadoIndex].valor_res
        }
      ]
    }

    await updateResultadosValor(resultadosEdit[selectedResultadoIndex].codigo_mue, resultadoToUpdate)
    .then((res) => {
      setResultados(resultadosEdit);
      if(resultadosEdit.findIndex((item, index) => (item.valor_res === "" || item.valor_res === null) && index !== selectedResultadoIndex) !== -1) {
        setSelectedResultadoIndex(resultadosEdit.findIndex((item, index) => (item.valor_res === "" || item.valor_res === null) && index !== selectedResultadoIndex))
      } else {
        if(selectedResultadoIndex + 1 < resultados.length) {
          setSelectedResultadoIndex(selectedResultadoIndex + 1);
        } else {
          setSelectedResultadoIndex(0);
        }
      }
      setIsUpdating(false);
    })
  }

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={isUpdating || resultados.length < 1 || selectedAnalisis === null}
          onClick={handleClickConfirmar}
        />

      </>
    );
  };
  return (
    <StyledModalcomponentMuestras sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <div className="cargaResultados">

        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <div className="divUnoInput">
              <Input
                  label="Cliente"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="cliente"
                  reactSelect
                  options={clientes}
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e);
                    fetchOrdenes(e);
                  }}
                  placeholder={selectedCliente === null ? "Seleccione un Cliente" : "Cliente"}
                  isDisabled={clientes.length < 1}
                  isClearable
                />
                <Input
                  label="Orden de Trabajo"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="ordenTrabajo"
                  reactSelect
                  options={ordenes}
                  value={selectedOrden}
                  onChange={(e) => {
                    setSelectedOrden(e);
                    fetchAnalisis(e);
                  }}
                  placeholder={isLoadingOrdenes ? "Cargando..." : selectedCliente !== null && ordenes.length < 1 ? "No se encontraron ordenes" : selectedCliente !== null && selectedOrden === null ? "Seleccione una Orden" : "Orden de Trabajo"}
                  isDisabled={ordenes.length < 1}
                  isClearable
                  isLoading={isLoadingOrdenes}
                />
            </div>
            <div className="divDosInput">
            <Input
                  label="Análisis"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="analisis"
                  reactSelect
                  options={analisis}
                  value={selectedAnalisis}
                  onChange={(e) => {
                    setSelectedAnalisis(e);
                    fetchResultados(e);
                  }}
                  placeholder={ isLoadingAnalisis ? "Cargando..." : selectedOrden !== null && analisis.length < 1 ? "No se encontraron Analisis" : selectedOrden !== null && selectedAnalisis === null ? "Seleccione un Analisis" : "Analisis"}
                  isDisabled={analisis.length < 1}
                  isClearable
                  isLoading={isLoadingAnalisis}
                />
            </div>
            { selectedAnalisis !== null && isLoadingResultados === false ?
            <div className="divTresInput">
              <div className="divInputsTres">
                <Input
                  value={ selectedResultadoIndex !== null ? resultados[selectedResultadoIndex].codigo_mue : ""}
                  label="Número de la Muestra"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="nroMuestra"
                  disabled
                />
                <Input
                  value={selectedResultadoIndex !== null && resultadosEdit[selectedResultadoIndex].valor_res !== null ? resultadosEdit[selectedResultadoIndex].valor_res : ""}
                  label="Valor Resultado"
                  classnamediv="divInput"
                  className="input"
                  variant="outlined"
                  size="medium"
                  name="valorResultado"
                  onChange={handleChangeResultado}
                />
              </div>
              <div className="divLabelInputTres">
                <p className="label">Punto: <span className="span">{selectedResultadoIndex !== null ? resultados[selectedResultadoIndex].punto_toma.description : ""}</span></p>
                <p className="label">Equipo: <span className="span">{selectedResultadoIndex !== null ? resultados[selectedResultadoIndex].equipo.description : ""}</span></p>
              </div>
            </div>
            : <></>}
            { selectedAnalisis !== null && isLoadingResultados === false ?
              <div className="divImagenes" style={{ display: "flex", gap: "5px" }}>
              {resultados.map((item, index) => ( item.valor_res === '' || item.valor_res === null ? <BoxResult active={index === selectedResultadoIndex} key={index} label={item.codigo_mue} sub={"S/V"} error onClick={() => setSelectedResultadoIndex(index)}/> : <BoxResult active={index === selectedResultadoIndex} key={index} label={item.codigo_mue} sub={item.valor_res} onClick={() => setSelectedResultadoIndex(index)}/>))}
            </div> 
            : <></>}
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default CargaManualResultadosModal 