import React, { useState, useEffect } from 'react';
import Tables from '../../../../components/Tables/Tables';
import HeaderTable from '../../../../components/HeaderTable/HeaderTable';
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from '../../../../components/ButtonsTable/ButtonsTable';
import styled from 'styled-components';
import Toast from '../../../../components/Toast/Toast';
import Modal2 from '../../../../components/Modal/Modal';
import {getTipoAnalisisPaginated, deleteTipoAnalisis } from '../../../../api/Configuracion';
import { CircularProgress } from "@mui/material";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "abreviatura", label: "Abreviatura" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
]

const TipodeAnalisis = () => {
  const [statusModal, setModalStatus] = useState(false);

  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoAnalisiss, setTipoAnalisiss] = useState([]);
  const [tipoAnalisisId, setTipoAnalisisId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchTipoAnalisisPaginated(paginaActual, orderBy, searchTerm, "primera")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchTipoAnalisisPaginated y searchTerm en la dependencia array


  const fetchTipoAnalisisPaginated = async (pagina, order, search, action) => {
    const res = await getTipoAnalisisPaginated(pagina, order, search);
    //console.log(res);
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {     
      setTimeout(() => {
        setSuccess(false);
      }, 1000)
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setTipoAnalisiss(res.data.items.map((res) => {
      return { 
        id: res.id, 
        codigo: res.codigo_tpana, 
        descripcion: res.desc_tpana, 
        abreviatura: res.abrev_tpana, 
        habilitado: res.status 
      };
    }));
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoAnalisisId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteTipoAnalisis(valueRow.id)
    }
  }
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleDeleteTipoAnalisis = (id) => {
    setIsLoading(true);
    // setSuccess(false);
    deleteTipoAnalisis(id)
      .then(() => {
        setIsLoading(false);
        setTipoAnalisisId("");
        setSuccess(true);
        setMsjToast("Tipo de de Análisis eliminado con exito");
        setKey(key+1);
        
        const paginaActualTemp = tipoAnalisiss.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);

        return fetchTipoAnalisisPaginated(
          paginaActualTemp, 
          orderBy, 
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        // setKey(key+1);
        setIsLoading(false);
        setMsjToast("Error al eliminar El tipo de Análisis")
        setError(true)
      });
  }

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchTipoAnalisisPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"tipoAnalisis"}
        onClose={({ msj, status }) => {
          setModalStatus(false);
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchTipoAnalisisPaginated(
              paginaActual, 
              orderBy, 
              searchTerm, 
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }else{
            fetchTipoAnalisisPaginated(
              paginaActual, 
              orderBy, 
              searchTerm, 
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Análisis"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nuevo tipo de análisis"}
        onClickButton={() => { 
          handleClickButtonHeader() 
        }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoAnalisiss}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => { handleOrder(value) }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoAnalisis(tipoAnalisisId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipodeAnalisis;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
   // height: 100vh;
  }
`