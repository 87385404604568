import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import {
  deleteTipoUnidad,
  getTipoUnidadesPaginated,
  getTipoUnidadesSearch
} from "../../../../api/Configuracion";
import { CircularProgress } from "@mui/material";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const TipoUnidades = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoUnidades, setTipoUnidades] = useState([]);
  const [tipoUnidadId, setTipoUnidadId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchTipoUnidadesPaginated(paginaActual, orderBy, searchTerm,"primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]);// Ignorando fetchTipoUnidadesPaginated y searchTerm en la dependencia array

  const fetchTipoUnidadesPaginated = async (pagina, order, search,action) => {
    const res = await getTipoUnidadesPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {     
      setTimeout(() => {
        setSuccess(false);
      }, 1000)
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setTipoUnidades(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_tpuni,
          descripcion: res.desc_tpuni,
          habilitado: res.status,
        };
      })
    )
  } 

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoUnidadId(valueRow.id);
      setModalStatus2(true);
      //handleDeleteUnidad(valueRow.id)
    }
  };
  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleDeleteTipoUnidad = (unidadId) => {
    setIsLoading(true);
    // setSuccess(false);
    setModalStatus2(false);
    deleteTipoUnidad(unidadId)
      .then(() => {
        setIsLoading(false);
        setTipoUnidadId("");
        setSuccess(true);
        setMsjToast("Tipo de Unidad eliminada con exito");
        setKey(key+1);

        const paginaActualTemp = tipoUnidades.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);

        return fetchTipoUnidadesPaginated(
          paginaActualTemp, 
          orderBy, 
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        //setKey(key+1);
        setIsLoading(false);
        setMsjToast("Error al eliminar El tipo de Unidad")
        setError(true)
      });
  };

   const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchTipoUnidadesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled >

      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"tipoUnidades"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchTipoUnidadesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }else{
            fetchTipoUnidadesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false);
            setMsjToast('');
          }, 1000)
          setModalStatus(false);
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipos de Unidades"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nuevo tipo de unidad"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoUnidades ? tipoUnidades : rows}
        cRows={20}
        pagination={true}
        totalRows={itemsCount}
        pages={pageCount}
        actualpage={paginaActual}
        page={(e) => setPaginaActual(e + 1)}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickSwitch={(value) => { handleOrder(value) }}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoUnidad(tipoUnidadId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoUnidades;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
   // height: 100vh;
  }
`;
