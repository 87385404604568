import React, { useReducer, useState, useEffect } from "react";
import Input from "../../../../Input/Input.jsx";
import CustomButton from "../../../../Button/CustomButton.tsx";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Toast from "../../../../Toast/Toast.jsx";
import Preloader from "../../../../Preloader/Preloader.jsx";
import { StyledModalcomponent } from "../../../Styled.jsx";
import {
  createComponente,
  updateComponente,
} from "../../../../../api/Configuracion";

const Componentes = (props) => {
  const propsComponentes = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos };
  };
  const { ver, editar, datos } = propsComponentes(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [form, setForm] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_FIELD":
          return { ...state, [action.field]: action.value };
        default:
          return state;
      }
    },
    {
      id: datos.id ?? "",
      codigo: datos.codigo ?? "",
      descripcion: datos.descripcion ?? "",
      habilitado: editar || ver
      ? datos.habilitado === "ACTIVE" || datos.habilitado === "SI"
      : true
    }
  );

  useEffect(() => {
    const campoValido = (campo) =>
      form[campo] !== "" && (campo !== "habilitado" || form[campo]);

    const camposaValidar = ["codigo", "descripcion"];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === "checkbox" ? checked : value;
      setForm({ type: "SET_FIELD", field: name, value: fieldValue });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.codigo.length > 20) {
      setMsjToast("El codigo es muy largo.");
      setError(true);
      return;
    }else if (form.descripcion.length > 200) {
      setMsjToast("La descripcion es muy larga.");
      setError(true);
      return;
    }

    setIsLoading(true);

    let formattedData = {
      id: form.id,
      codigo_com: form.codigo.toString(),
      desc_com: form.descripcion,
      tipo_comp_id: "1",
      created_by: "cabs",
      created_date: new Date(),
      status: form.habilitado ? "SI" : "NO",
      tenantId: 1,
      eliminado: false,
    };
    // alert(JSON.stringify(formattedData))

    if (editar) {
      updateComponente(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Componente modificado con éxito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se Pudo editar el Componente.",
            status: "error",
          });
        });
    } else {
      createComponente(formattedData)
        .then((res) => {
          setIsLoading(false);
          props.onClick({
            msj: "Componente creado con exito.",
            status: "success",
          });
        })
        .catch((err) => {
          setIsLoading(false);
          props.onClick({
            msj: "No se pudo crear el Componente.",
            status: "error",
          });
        });
    }
  };
  //console.log(form)
  const titulo = () => {
    const titulo = ver
      ? "Ver componente"
      : editar
      ? "Editar componente"
      : "Crear o editar componentes";
    return titulo;
  };
  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto}
          />
        )}
      </>
    );
  };

  return (
    <StyledModalcomponent>
      <Preloader
        title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading}
      />

      <div classname="toast">
        {success ? (
          <Toast title={msjToast} timeClose={3000} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            timeClose={3000}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="componentes">
        <div className="divLabel">
          <p className="titulo"> {titulo()}</p>
        </div>
        <div className="divInputsItem" onSubmit={handleSubmit}>
          <form className="formulario">
            <div className="divPrimerInput">
              <Input
                value={form.codigo}
                label="Código"
                className="inputCodigo input"
                variant="outlined"
                size={"medium"}
                name="codigo"
                onChange={handleChange}
                disabled={ver}
              />
              <FormControlLabel
                control={
                  <Switch
                    name="habilitado"
                    disabled={ver}
                    checked={form.habilitado}
                    onChange={handleChange}
                  />
                }
                label="Habilitar"
              />
            </div>

            <Input
              value={form.descripcion}
              label="Descripción"
              className="inputDesc input"
              variant="outlined"
              size={"medium"}
              name="descripcion"
              onChange={handleChange}
              disabled={ver}
            />
            <div className="divButtonsItem">{renderButtons()}</div>
          </form>
        </div>
      </div>
    </StyledModalcomponent>
  );
};

export default Componentes;
