import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors, fonts } from "../../assets/styles/theme.ts";
import styled from "styled-components";
interface Props {
  img?: string;
  alt?: string;
  className?: string;
  width?: string;
  height?: string;
  material?: React.ReactNode;
  onClick?: () => void;
  label?: any;
}

const RenderImg: React.FC<Props> = ({
  img,
  alt,
  material,
  className,
  width,
  height,
  onClick,
  label }: Props) => {
  return <>
    {material ? material :
      label ? <Box sx={{ display: "flex" }}>
        <img
          src={img}
          alt={alt}
          className={className}
          width={width}
          height={height}
          onClick={onClick} />
        <StyledTypography>{label}</StyledTypography>
      </Box>
        :
        <img src={img} alt={alt} className={className} width={width} height={height} onClick={onClick} />
    }
  </>
}

export default RenderImg

const StyledTypography = styled(Typography)`
    font-family: ${fonts.primary} !important;
    color: ${colors.blueDark} !important;
    font-weight: 600 !important;
`;