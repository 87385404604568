import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import Modal2 from "../../../../components/Modal/Modal";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import {
  deleteMetodo,
  getMetodosPaginated,
} from "../../../../api/Configuracion";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import { CircularProgress } from "@mui/material";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const Eventos = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [metodos, setMetodos] = useState([]);
  const [metodoId, setMetodoId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchMetodosPaginated(paginaActual, orderBy, searchTerm, "primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchMetodosPaginated y searchTerm en la dependencia array

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setMetodoId(valueRow.id);
      setModalStatus2(true);
      //handleDeleteMetodo(valueRow.codigo)
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDeleteMetodo = (id) => {
    setIsLoading(true);
    setModalStatus2(false);
    deleteMetodo(id)
      .then(() => {
        setIsLoading(false);
        setMetodoId("");
        setKey(key + 1);
        setMsjToast("Tipo de Evento eliminado con exito.");
        setSuccess(true);

        const paginaActualTemp = metodos.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);

        return fetchMetodosPaginated(paginaActualTemp, orderBy, searchTerm, "delete");
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar El tipo de Método");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const fetchMetodosPaginated = async (pagina, order, search, action) => {
    const res = await getMetodosPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setTimeout(() => {
        setSuccess(false);
      }, 1000)
      
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setMetodos(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_met,
          descripcion: res.desc_met,
          habilitado: res.status,
        };
      })
    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchMetodosPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999",
          }}
        />
      )}
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"metodos"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchMetodosPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchMetodosPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          }
          setModalStatus(false);
        }}
      />
      <HeaderTable
        styledHead="cfg"
        cfg
        title="Métodos"
        valueBuscador={(value) => handleBuscador(value)}
        labelButton={"Nuevo método"}
        onClickButton={(e) => {
          handleClickButtonHeader(e);
        }}
        value={searchTerm}
        search
        isClearable={() => {
          handleBuscador("");
        }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={metodos}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteMetodo(metodoId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Eventos;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
