    import React, { useReducer, useState, useEffect } from "react";
    import Input from "../../../../Input/Input";
    import CustomButton from "../../../../Button/CustomButton.tsx";
    import Preloader from "../../../../Preloader/Preloader.jsx";
    import Toast from "../../../../Toast/Toast.jsx";
    import { StyledModalcomponentClientes } from "../../../Styled.jsx";
    import {
      getClienteOptions,
      getPlantaOptions,
      updateEquipos,
      createEquipos,
      getPlantabyCliente2,
      getPlantaOptionsWithCliente,
    } from "../../../../../api/Clientes.js";

    const EquiposModal = (props) => {
      const propsEquipos = (datosUnidades) => {
        const ver = datosUnidades?.button === "ver" ?? false;
        const editar = datosUnidades?.button === "editar" ?? false;
        const datos = datosUnidades?.datos ?? [];
        return { ver, editar, datos };
      };
      const { ver, editar, datos } = propsEquipos(props.datos);

      const [formCompleto, setFormCompleto] = useState(false);
      const [success, setSuccess] = useState(false);
      const [error, setError] = useState(false);
      const [msjToast, setMsjToast] = useState("");
      const [clientes, setClientes] = useState([]);
      const [plantas, setPlantas] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      const [selectedOptioncli, setSelectedOptioncli] = useState(null);
      const [selectedOptionpla, setSelectedOptionpla] = useState(null);

      const [form, setForm] = useReducer(
        (state, action) => {
          switch (action.type) {
            case "SET_FIELD":
              return { ...state, [action.field]: action.value };
            default:
              return state;
          }
        },
        {
          id: datos.id ?? "",
          cliente: datos.cliente ?? "",
          planta: datos.planta ?? "",
          codigo: datos.codigo ?? "",
          denominacion: datos.denominacion ?? "",
          tagPlanta: datos.tagplanta ?? "",
          area: datos.area ?? "",
          plantaId: datos.plantaId ?? "",
          selectedOption: props.datos.selectedOption ?? "",
        }
      );

      useEffect(() => {
        const campoValido = (campo) => form[campo] !== "";
        //plantaid
        const camposaValidar = [
          "cliente",
          "denominacion",
          "plantaId",
        ];
        const formularioCompleto = camposaValidar.every(campoValido);
        setFormCompleto(formularioCompleto);
      }, [form]);

      useEffect(() => {

        const fetchclientes = async () => {
          const res = await getClienteOptions();
          const unidades = res?.data?.map((item) => ({
            value: item.id,
            label: item.desc_cli,
          }));
          setClientes(unidades);

          if (datos && form.selectedOption==="") {
            const matchOption = unidades.find(
              (option) => option.label === datos.cliente
            );
            if (matchOption) {

              setSelectedOptioncli(matchOption);
            } /*else if (unidades.length > 0 && editar) {
              setSelectedOptioncli(unidades[0]);
            }*/
          } else if (unidades.length > 0 && editar) {
            //setSelectedOptioncli(unidades[0]);
          }
        };
        fetchclientes();

        const fetchPlantas = async () => {
          const res = await getPlantaOptionsWithCliente();
          const unidades = res?.data?.map((item) => ({
            value: item.id,
            label: item.desc_pla,
            cliente:item.cliente,
          }));
          setPlantas(unidades);
          if (datos  ) {
            let matchOption = '';

            if(form.selectedOption!==""){
              matchOption = unidades.find(
                  (option) => option.value === props.datos.selectedOption.value
              );
            }else{

              matchOption = unidades.find(
                  (option) => option.value === datos.plantaId
              );

            }

            if (matchOption) {
              setSelectedOptionpla(matchOption);
              setForm({ type: "SET_FIELD", field: "planta", value: matchOption.value });
              setForm({ type: "SET_FIELD", field: "plantaId", value: matchOption.value });


              let clienteSeleccionado = {value: matchOption.cliente?.id, label: matchOption.cliente?.description}
              setSelectedOptioncli(clienteSeleccionado);
              setForm({ type: "SET_FIELD", field: "cliente", value: clienteSeleccionado.value });
            } /*else if (unidades.length > 0 && editar) {
              setSelectedOptionpla(unidades[0]);
            }*/
          } /*else if (unidades.length > 0 && editar) {
            setSelectedOptionpla(unidades[0]);
          }*/
        };
        fetchPlantas();
      }, []);

      useEffect(() => {
        if ((selectedOptioncli !== null && selectedOptioncli !== "") && !ver) {
          getPlantabyCliente2(selectedOptioncli.value).then((res) => {
            setPlantas(
              res.data.map((res) => {
                return { label: res.desc_pla, value: res.id };
              })
            );
            //setEquipos([])
          });
        }
      }, [selectedOptioncli]);

      const handleChange = (e, nameReactSelect) => {
        if (e.target) {
          const { name, value, checked, type } = e.target;
          const fieldValue = type === "checkbox" ? checked : value;
          setForm({ type: "SET_FIELD", field: name, value: fieldValue });
        } else {
          const value = e.value;
          const name = nameReactSelect;
          const label = e.label;

          if (name === "cliente") {
            // El cliente no se guarda, solo sirve para obtener las plantas.
            setSelectedOptionpla('');
            setSelectedOptioncli(e);
            setForm({ type: "SET_FIELD", field: name, value: label });
            setForm({ type: "SET_FIELD", field: "plantaId", value: "" })
          } else if (name === "plantaId") {
            //REVISAR ACA
            setSelectedOptionpla(e);
            setForm({ type: "SET_FIELD", field: name, value: value });
          }
        }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let formattedData = {
          id: form.id ? form.id : 0,
          codigo_equi: form.codigo,
          desc_equi: form.denominacion,
          tag_externo_equi: form.tagPlanta,
          area_equi: form.area,
          tipo_equi_id: "32132",
          planta_id: parseInt(form.plantaId),
          created_by: "dasfdasd",
          created_date: new Date(),
          last_modified_by: "1",
          last_modified_date: new Date(),
          status: "SI",
          eliminado: false,
          tenantId: "1",
        };

        if (editar) {
          updateEquipos(formattedData)
            .then((res) => {
              setIsLoading(false);
              props.onClick({
                msj: "Equipo modificado con exito.",
                status: "success",
              });
            })
            .catch((err) => {
              setIsLoading(false);
              props.onClick({
                msj: "No se Pudo modificar el equipo.",
                status: "error",
              });
            });
        } else {
          createEquipos(formattedData)
            .then((res) => {
              setIsLoading(false);
              props.onClick({
                msj: "Equipo creado con exito.",
                status: "success",
              });
            })
            .catch((err) => {
              setIsLoading(false);
              props.onClick({
                msj: "No se pudo crear el equipo.",
                status: "error",
              });
            });
        }
      };

      const titulo = () => {
        const titulo = ver
          ? "Ver equipo"
          : editar
          ? "Editar equipo"
          : "Crear o editar equipos";
        return titulo;
      };

      const renderButtons = () => {
        return (
          <>
            <CustomButton
              onClick={props.onClick}
              label={ver ? "Cerrar" : "Cancelar"}
              variant="contained"
              className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
            />
            {!ver && (
              <CustomButton
                label={"Confirmar"}
                variant="contained"
                className={`buttonColorDefault btn`}
                type="submit"
                disabled={!editar ? !formCompleto : selectedOptionpla === '' ? true : false}
              />
            )}
          </>
        );
      };

      return (
        <StyledModalcomponentClientes>
          <Preloader
            title="Cargando..."
            subtitle={"Espere unos Segundos por favor..."}
            loading={isLoading}
          />
          <div classname="toast">
            {success ? (
              <Toast title={msjToast} close={() => setSuccess(false)} />
            ) : null}
            {error ? (
              <Toast
                type={"error"}
                title={
                  "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
                }
                close={() => setError(false)}
              />
            ) : null}
          </div>

          <div className="equipos">
            <div className="divLabel">
              <p className="titulo"> {titulo()} </p>
            </div>
            <div className="divInputsItem">
              <form className="formulario" onSubmit={handleSubmit}>
                <div className="divUno d-flex ">
                  <div style={{ width: "50%" }}>
                    <Input
                      reactSelect
                      options={clientes}
                      value={selectedOptioncli}
                      onChange={(e) => {
                        handleChange(e, "cliente");
                      }}
                      isDisabled={ver && true}
                      placeholder={
                        ver ? "Cliente" : "Seleccione el cliente"
                      }
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Input
                      reactSelect
                      options={plantas}
                      value={selectedOptionpla}
                      onChange={(e) => {
                        handleChange(e, "plantaId");
                      }}
                      isDisabled={
                        datos.length === 0 && form.cliente === "" ? true : ver
                      }
                      placeholder={
                        ver ? "Planta" : editar ? "Seleccione la planta" : form.cliente === ""
                          ? "Seleccione un cliente primero"
                          : "Seleccione la planta"
                      }
                    />
                  </div>
                </div>
                <div className="divDos d-flex divs">
                  {ver || editar ? (
                    <Input
                      value={form.codigo}
                      label="Código"
                      classnamediv="divCodigo divInput"
                      className="input inputCodigo"
                      variant="outlined"
                      size={"medium"}
                      name="codigo"
                      onChange={handleChange}
                      disabled={ true }
                    />
                  ) : (
                    <></>
                  )}
                  <Input
                    value={form.denominacion}
                    label="Denominación"
                    classnamediv="divDenominacion divInput"
                    className="input inputDenominacion"
                    variant="outlined"
                    size={"medium"}
                    name="denominacion"
                    onChange={handleChange}
                    disabled={ver && true}
                  />
                </div>
                <div className="divTres d-flex divs">
                  <Input
                    value={form.tagPlanta}
                    label="TAG Planta"
                    classnamediv="divTagPlanta divInput"
                    className="input inputTagPlanta"
                    variant="outlined"
                    size={"medium"}
                    name="tagPlanta"
                    onChange={handleChange}
                    disabled={ver && true}
                  />
                  <Input
                    value={form.area}
                    label="Area"
                    classnamediv="divArea divInput"
                    className="input inputArea"
                    variant="outlined"
                    size={"medium"}
                    name="area"
                    onChange={handleChange}
                    disabled={ver && true}
                  />
                </div>

                <div className="divButtonsItem">{renderButtons()}</div>
              </form>
            </div>
          </div>
        </StyledModalcomponentClientes>
      );
    };

    export default EquiposModal;
