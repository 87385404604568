  import React, { useReducer, useState, useEffect } from "react";
  import Input from "../../../../Input/Input.jsx";
  import CustomButton from "../../../../Button/CustomButton.tsx";
  // import MenuItem from '@mui/material/MenuItem';
  import Preloader from "../../../../Preloader/Preloader.jsx";
  import Toast from "../../../../Toast/Toast.jsx";
  import { StyledModalcomponentClientes } from "../../../Styled.jsx";
  import {
    getClienteOptions,
    createPlanta,
    updatePlanta,
    getPlantaOptionsWithCliente,
  } from "../../../../../api/Clientes.js";
 //prueba pre filtrado
  const PlantasModal = (props) => {
    const propsPlantas = (datosUnidades) => {
      const ver = datosUnidades?.button === "ver" ?? false;
      const editar = datosUnidades?.button === "editar" ?? false;
      const datos = datosUnidades?.datos ?? [];
      return { ver, editar, datos };
    };
    const { ver, editar, datos } = propsPlantas(props.datos);

    const [formCompleto, setFormCompleto] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msjToast, setMsjToast] = useState("");
    const [clientes, setClientes] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const [form, setForm] = useReducer(
      (state, action) => {
        switch (action.type) {
          case "SET_FIELD":
            return { ...state, [action.field]: action.value };
          default:
            return state;
        }
      },
      {
        id: datos.id ?? "",
        cliente: datos.cliente ?? "",
        codigo: datos.codigo ?? "",
        denominacion: datos.denominacion ?? "",
        emailsdeNotificacion: datos.emails ?? "",
        selectedOption: props.datos.selectedOption ?? "",
      }
    );

    useEffect(() => {
      const fetchClientes = async () => {
        const res = await getClienteOptions();
        const unidades = res?.data?.map((item) => ({
          value: item.id,
          label: item.desc_cli,
        }));
        setClientes(unidades);
        if (datos ) {
          let matchOption = '';

          if(form.selectedOption!==""){
            //esto es para nuevo, si viene filtrado el cliente, que lo pre cargue.
            matchOption = unidades.find(
                (option) => option.label === props.datos.selectedOption.label
            );
          }else{
           matchOption = unidades.find(
            (option) => option.label === datos.cliente
          ); }

          if (matchOption) {
            setSelectedOption(matchOption);
          } else if (unidades.length > 0 && editar) {
            setSelectedOption(unidades[0]);
          }
        } else if (unidades.length > 0 && editar) {
          setSelectedOption(unidades[0]);
        }
      };
      fetchClientes();
    }, []);

    useEffect(() => {
      const campoValido = (campo) => form[campo] !== "";

      const camposaValidar = [
        "cliente",
        "codigo",
        "denominacion",
        "emailsdeNotificacion",
        "telefono",
        "logo",
      ];
      const formularioCompleto = camposaValidar.every(campoValido);
      setFormCompleto(formularioCompleto);
    }, [form]);

    useEffect(() => {
      if (selectedOption) {
        setForm({
          type: "SET_FIELD",
          field: "cliente",
          value: selectedOption.value,
        });
      }
    }, [selectedOption]);

    const handleChange = (e, nameReactSelect) => {
      if (e.target) {
        const { name, value, checked, type } = e.target;
        const fieldValue = type === "checkbox" ? checked : value;
        setForm({ type: "SET_FIELD", field: name, value: fieldValue });
      } else {
        const value = e.value;
        const name = nameReactSelect;
        setSelectedOption(e);
        setForm({ type: "SET_FIELD", field: name, value: value });
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setIsLoading(true);

      let formattedData = {
        id: form.id,
        codigo_pla: form.codigo,
        desc_pla: form.denominacion,
        cliente_id: form.cliente.toString(),
        created_by: "dasfdasd",
        created_date: new Date(),
        status: "SI",
        email_info_pla: form.emailsdeNotificacion,
        tenantId: "1",
        eliminado: false,
      };
      if (editar) {
        updatePlanta(formattedData)
          .then((res) => {
            setIsLoading(false);
            props.onClick({
              msj: "Planta modificada con exito.",
              status: "success",
            });
          })
          .catch((err) => {
            setIsLoading(false);
            props.onClick({
              msj: "No se pudo editar la Planta.",
              status: "error",
            });
          });
      } else {
        createPlanta(formattedData)
          .then((res) => {
            setIsLoading(false);
            props.onClick({
              msj: "Planta creada con exito.",
              status: "success",
            });
          })
          .catch((err) => {
            setIsLoading(false);
            if(err.response.data.includes("Duplicate entry")){
              props.onClick({
                msj: "Codigo ya existente.",
                status: "error",
              });
            } else {
              props.onClick({
                msj: "No se pudo crear la planta.",
                status: "error",
              });
            }
          });
      }
    };

    const titulo = () => {
      const titulo = ver
        ? "Ver planta"
        : editar
        ? "Editar planta"
        : "Crear o editar planta";
      return titulo;
    };

    const renderButtons = () => {
      return (
        <>
          <CustomButton
            onClick={props.onClick}
            label={ver ? "Cerrar" : "Cancelar"}
            variant="contained"
            className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
          />
          {!ver && (
            <CustomButton
              label={editar ? "Confirmar" : "Confirmar"}
              variant="contained"
              className={`buttonColorDefault btn`}
              type="submit"
              disabled={!formCompleto}
            />
          )}
        </>
      );
    };

    return (
      <StyledModalcomponentClientes>
        <Preloader
          title="Cargando..."
          subtitle={"Espere unos Segundos por favor..."}
          loading={isLoading}
        />

        <div classname="toast">
          {" "}
          {success ? (
            <Toast title={msjToast} close={() => setSuccess(false)} />
          ) : null}
          {error ? (
            <Toast
              type={"error"}
              title={
                "Error al " + (editar ? "modificar" : "crear") + "la unidad ?"
              }
              close={() => setError(false)}
            />
          ) : null}
        </div>

        <div className="plantas">
          <div className="divLabel">
            <p className="titulo"> {titulo()} </p>
          </div>
          <div className="divInputsItem">
            <form className="formulario" onSubmit={handleSubmit}>
              <Input
                reactSelect
                options={clientes}
                value={selectedOption}
                onChange={(e) => {
                  handleChange(e, "cliente");
                }}
                isDisabled={ver && true}
                placeholder="Cliente"
              />
              <Input
                value={form.codigo}
                label="Código"
                className="input inputCodigo"
                variant="outlined"
                size={"medium"}
                name="codigo"
                type="number"
                disabled={ver}
                onChange={handleChange}
              />
              <Input
                value={form.denominacion}
                label="Denominación"
                className="input inputDenominacion"
                variant="outlined"
                size={"medium"}
                name="denominacion"
                disabled={ver}
                onChange={handleChange}
              />
              <Input
                value={form.emailsdeNotificacion}
                label="Emails de Notificación"
                className="input inputEmailNoti"
                variant="outlined"
                size={"medium"}
                type="email"
                name="emailsdeNotificacion"
                disabled={ver}
                onChange={handleChange}
              />

              <div className="divButtonsItem">{renderButtons()}</div>
            </form>
          </div>
        </div>
      </StyledModalcomponentClientes>
    );
  };

  export default PlantasModal;
