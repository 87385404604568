import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import styled from "styled-components";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import { CircularProgress } from "@mui/material";
import {
  getUnidadesPaginated,
  deleteUnidad,
  getUnidadesSearch,
} from "../../../../api/Configuracion";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "simbolo", label: "Símbolo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const Unidades = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [statusModal2, setModalStatus2] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [unidadId, setUnidadId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  // useEffect(() => {
  //   getUnidadesPaginated(1, "", "")
  //     .then((res) => {
  //       setPageCount(res.data.totalPages);
  //       setItemsCount(res.data.totalItems);
  //       setRows(
  //         res.data.items.map((item) => {
  //           return {
  //             id: item.id,
  //             codigo: item.codigo_uni,
  //             descripcion: item.desc_uni,
  //             tipo: item.tipo_uni.desc_uni,
  //             tipoId: item.tipo_uni.id,
  //             simbolo: item.simbolo_uni,
  //             habilitado: item.status,
  //           };
  //         })
  //       );
  //     })
  //     .catch((err) =>
  //       console.error("Error al obtener datos de allunidades:", err)
  //     );
  // }, []);

  useEffect(() => {
    fetchUnidadesPaginated(paginaActual, orderBy, searchTerm,"primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchUnidadesPaginated y searchTerm en la dependencia array

  // useEffect(() => {
  //   if (searchTerm === "") {
  //     getUnidadesPaginated(paginaActual, orderBy, searchTerm)
  //       .then((res) => {
  //         setPageCount(res.data.totalPages);
  //         setItemsCount(res.data.totalItems);
  //         setRows(
  //           res.data.items.map((item) => {
  //             return {
  //               id: item.id,
  //               codigo: item.codigo_uni,
  //               descripcion: item.desc_uni,
  //               tipo: item.tipo_uni.desc_uni,
  //               tipoId: item.tipo_uni.id,
  //               simbolo: item.simbolo_uni,
  //               habilitado: item.status,
  //             };
  //           })
  //         );
  //       })
  //       .catch((err) =>
  //         console.error("Error al obtener datos de allunidades:", err)
  //       );
  //   } else {
  //     setPaginaActual(1);
  //     getUnidadesSearch("", "", searchTerm)
  //       .then((res) => {
  //         if (res.data && res.data.items && res.data.items.length > 0) {
  //           setPageCount(1);
  //           setItemsCount(res.data.totalItems);
  //           setRows(
  //             res.data.items.map((item) => {
  //               return {
  //                 id: item.id,
  //                 codigo: item.codigo_uni,
  //                 descripcion: item.desc_uni,
  //                 tipo: item.tipo_uni.desc_uni,
  //                 tipoId: item.tipo_uni.id,
  //                 simbolo: item.simbolo_uni,
  //                 habilitado: item.status,
  //               };
  //             })
  //           );
  //         } else {
  //           setRows([]);
  //           setPageCount(0);
  //           setItemsCount(0);
  //         }
  //       })
  //       .catch((err) =>
  //         console.error("Error al obtener datos de allunidades:", err)
  //       );
  //   }
  // }, [searchTerm]);

  const fetchUnidadesPaginated = async(pagina, order,search, action) => {
    const res = await getUnidadesPaginated(pagina, order,search);
    
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }
    setRows(
      res.data.items.map((item) => {
        return {
          id: item.id,
          codigo: item.codigo_uni,
          descripcion: item.desc_uni,
          tipo: item.tipo_uni.desc_uni,
          tipoId: item.tipo_uni.id,
          simbolo: item.simbolo_uni,
          habilitado: item.status,
        };
      })
    )
  }

  const handleDeleteUnidad = (unidadId) => {
    setIsLoading(true);
    // setSuccess(true);
    setModalStatus2(false);
    deleteUnidad(unidadId)
      .then(() => {
        setIsLoading(false);
        setUnidadId("");
        setSuccess(true);
        setMsjToast("Unidad eliminada con exito.");
        setKey(key + 1); 

        const paginaActualTemp = rows.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);
        
        return fetchUnidadesPaginated(
          paginaActualTemp,
          orderBy,
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setMsjToast("Error al eliminar la Unidad.");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value, datos: valueRow });
    } else {
      setUnidadId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchUnidadesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled >
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"unidades"}
        onClose={({ msj, status }) => {
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchUnidadesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              msj.includes("cread") ? "new" : ""
            )
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }else{
            fetchUnidadesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              "ver"
            )
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
          setModalStatus(false);
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Unidades"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nueva unidad"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={rows}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteUnidad(unidadId);
        }}
      />
    </Styled>
  );
};

export default Unidades;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  min-height: 100vh;
  .styledTables {
    //height: 100vh;
  }
`;
