import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Preloader from '../../../Preloader/Preloader.jsx';


import { StyledModalProveedores } from '../../Styled.jsx';
import { getProveedores, updateProveedores, createProveedores } from '../../../../api/Clientes.js';
import Toast from '../../../Toast/Toast.jsx';

const ProveedoresModal = (props) => {

  const propsProveedores = (datosUnidades) => {
    const ver = datosUnidades?.button === "ver" ?? false;
    const editar = datosUnidades?.button === "editar" ?? false;
    const datos = datosUnidades?.datos ?? [];
    return { ver, editar, datos }
  }
  const { ver, editar, datos } = propsProveedores(props.datos);

  const [formCompleto, setFormCompleto] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [proveedores, setProveedores] = useState([]);

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    id: datos.id ?? '',
    codigo: datos.codigo ?? '',
    denominacion: datos.denominacion ?? '',
    direccion: datos.direccion ?? '',
    email: datos.email ?? '',
    telefono: datos.telefono ?? '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['codigo', 'denominacion'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setSelectedOption(e);
      setForm({ type: 'SET_FIELD', field: name, value: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formattedData = 
    {
      "id": form.id,
      "codigo_prv": form.codigo,
      "tipo_prv": "tipo_de_proveedor",
      "desc_prv": form.denominacion,
      "inicio_prv": new Date(),
      "emails_prv": form.email,
      "telefonos_prv": form.telefono,
      "tipo_doc_prv": "tipo_de_documento",
      "numero_doc_prv": "123456789",
      "direccion_prv": form.direccion,
      "complemento_dom_prv": "Complemento de domicilio",
      "barrio_prv": "Barrio del proveedor",
      "localidad_prv": "Localidad del proveedor",
      "created_by": "usuario_creador",
      "created_date": new Date(),
      "last_modified_by": "usuario_modificador",
      "last_modified_date":new Date(),
      "status": "SI",
      "eliminado":false,
      "tenantId":"1"
    }

      if(editar){
        updateProveedores(formattedData).then(res => {
          setSuccess(true);
          setIsLoading(false);

          setMsjToast("Proveedor modificado con exito");
          setTimeout(() => {props.onClick()}, 100);
        });
      }else{ 
        createProveedores(formattedData).then(res => {
          props.onClick({
            msj: "Proveedor creado con éxito"
          });
          setSuccess(true);
          setIsLoading(false);

        setMsjToast("Proveedor creado con éxito");
        setTimeout(() => {props.onClick()}, 100);
        }).catch((error)=>{
          setIsLoading(false)
        });
      }
    
  }
  const titulo = () => {
    const titulo = ver ? "Ver Proveedor" : editar ? "Editar Proveedor" : "Crear o editar Proveedor";
    return titulo
  }


  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={props.onClick}
          label={ver ? "Cerrar" : "Cancelar"}
          variant="contained"
          className={`buttonColor${ver ? "Default" : "Disabled"} btn`}
        />
        {!ver && (
          <CustomButton
            label={editar ? "Confirmar" : "Confirmar"}
            variant="contained"
            className={`buttonColorDefault btn`}
            type="submit"
            disabled={!formCompleto }
          />
        )}
      </>
    );
  };
  return (
    <StyledModalProveedores>
  <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
    
    <div classname="toast" >        {success? <Toast title={msjToast} close={() => setSuccess(false)}/> : null}
        {error? <Toast type={"error"} title={"Error al " + (editar ? "modificar" : "crear") + "Proveedor ?"} close={() => setError(false)}/> : null}
      </div>
      <div className="proveedores">
        <div className="divLabel">
          <p className="titulo"> {titulo()} </p>
        </div>
        <div className="divInputsItem">
          <form className="formulario" onSubmit={handleSubmit}>
            <div className='col col-1'><Input
              value={form.codigo}
              label="Código"
              className="inputTelefono input"
              variant="outlined"
              size={"medium"}
              name="codigo"
              disabled={ver}
              onChange={handleChange}
            /></div>
            <Input
              value={form.denominacion}
              label="Denominación"
              className="inputDesc inputDenominacion input"
              variant="outlined"
              size={"medium"}
              name="denominacion"
              disabled={ver}
              onChange={handleChange}
            />
            <Input
              value={form.direccion}
              label="Dirección"
              className="inputCodigo inputDireccion input"
              variant="outlined"
              size={"medium"}
              name="direccion"
              disabled={ver}
              onChange={handleChange}
            />
            <div className="divUltimoInputs">
              <Input
                value={form.email}
                label="Email"
                className="inputDesc inputEmail input"
                variant="outlined"
                size={"medium"}
                name="email"
                disabled={ver}
                type="email"
                onChange={handleChange}
              />
              <Input
                value={form.telefono}
                label="Teléfono"
                className="inputDesc inputTelefono input"
                variant="outlined"
                size={"medium"}
                disabled={ver}
                name="telefono"                
                onChange={handleChange}
              />
            </div>



            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </form>
        </div>
      </div>
    </StyledModalProveedores>
  )
}

export default ProveedoresModal