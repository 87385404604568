import React, { useCallback, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import CustomButton from "../Button/CustomButton.tsx";
import {
  Divider,
} from "@mui/material";
import {
  DeleteOutlined,
  CloudDownload,
} from "@mui/icons-material";
import { colors } from "../../assets/styles/theme.ts";

const DropZoneZipImages = (props) => {
  //const [files, setFiles] = useState([]);
  const inputRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    const processFilesToBase64 = (files) =>
      Promise.all(
        files.map(
          (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                if (reader.result) {
                  const base64String = reader.result.split(",")[1];

                  resolve({
                    file: base64String,
                    nombre: file.name
                  });
                } else {
                  reject(new Error(`Error leyendo el archivo: ${file.name}`));
                }
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            })
        )
      );
  
    processFilesToBase64(acceptedFiles)
      .then((filesWithBase64) => {
        if (props.onUploadFiles) {
          props.onUploadFiles([filesWithBase64]);
        }
      })
      .catch((error) => {
        console.error("Error al procesar archivos a base64", error);
      });
  }, [props]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleDownload = (file, index) => {
    // Crear un elemento de enlace
    const a = document.createElement("a");
  
    // Formatear el URI base64 con el tipo de contenido
    const base64String = `data:${file.content_type};base64,${file.imagen_mue_base64}`;
    a.href = base64String;
  
    // Asignar un nombre al archivo descargado
    a.download = file.nombre || "archivo_descargado";
  
    // Agregar el enlace al DOM, hacer clic y luego removerlo
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  
    // Ejecutar el callback si se proporciona
    if (props.onClickDownload) {
      props.onClickDownload(file, index);
    }
  };

  const handleDelete = (file, index) => {
    //setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (props.onClickDelete) {
      props.onClickDelete(file, index)
    }
  };
  const openDialog = () => {
    inputRef.current.click();
  };
  return (
    <Styled>
      <div {...getRootProps()} {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} ref={inputRef} />
        <div className="divButton">
          <CustomButton
            label="Click para subir el archivo .zip con las imagenes a importar"
            onClick={openDialog}
            className="button"
            size="medium"
          />
        </div>
        <div className="divFiles">
          {props.archivos.map((fileData, index) => (
            <div sx={{ display: "flex", flexDirection: "column" }} key={index}>
              <div className="divFile" key={index}>
                <p className="labelFile">{ fileData.nombre || "archivo - " + index}</p>
                <div className="divButtons">
                  <CustomButton
                    onClick={() => handleDelete(fileData, index)}
                    startIcon={<DeleteOutlined />}
                    className="buttonDelete"
                  />
                </div>
              </div>
              <Divider
                orientation="horizontal"
                sx={{ backgroundColor: colors.turquoise }}
              />
            </div>
          ))}
        </div>
      </div>
    </Styled>
  );
};

export default DropZoneZipImages;
const Styled = styled.div`
  .dropzone {
    border: 2px dashed #afccd5;
    padding: 5px 0px;
    text-align: center;
    position: relative;
    z-index: 0;
  }
  .label {
    font-family: Inter;
    font-weight: bold;
    margin: 0px;
  }
  .divFiles {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 50%;

    .divFile {
      display: flex;
      align-items: center;
      .labelFile {
        font-family: Inter;
        font-weight: bold;
        font-size: 15px;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
        margin: 0px;
      }
      .divButtons {
        display: flex;
        align-items: center;
        .buttonDownload,
        .buttonDelete {
          color: ${colors.turquoise};
          svg {
            font-size: 30px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .divFiles {
      width: 90%;
      gap: 10px;
      .divFile {
        flex-direction: column;
        .labelFile{
        text-align: center;
        }
      }
    }
  }
`;
