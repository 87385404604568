import React, { useState } from 'react';
import styled from "styled-components";
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import Tables from '../../components/Tables/Tables';
import Modal from '../../components/Modal/Modal';
import { buttonsReportes } from '../../components/ButtonsTable/ButtonsTable';

const columns = [
  { id: "fReporte", label: "Fecha reporte" },
  { id: "tReporte", label: "Tipo de Reporte" },
  { id: "autor", label: "Autor" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
]
const rows = [
  {
    fReporte: "2024-12-01",
    tReporte: "Financiero",
    autor: "Juan Pérez",
    cliente: "ACME Corp."
  }
];

const Reportes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }
  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {

    }
  }
  return (
    <Styled>
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"reportes"}
        onClose={() => { setModalStatus(false); }}
      />
      <HeaderTable
        styledHead="cfg"
        proveedores
        title="Reportes"
        labelButton={"Nuevo Reporte"}
        // valueBuscador={(value) => {
        //   setPaginaActual(1);
        //   setSearchTerm(value)
        // }}
        onClickButton={() => { handleClickButtonHeader() }}
        // selectInput={(e) => { setSearchTerm(e.target.value.label) }}
        // buttonSet={() => { setSearchTerm("") }}
        // value={searchTerm}
        search
      // isClearable={() => { setSearchTerm("") }}
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        cRows={20}
        rows={rows}
        // totalRows={itemsCount}
        // pagination={true}
        // onClickSwitch={(value) => { setOrderBy(value) }}
        // page={(e) => setPaginaActual(e + 1)}
        // pages={pageCount}
        // actualpage={paginaActual}
        // // valueBuscador={valueBuscador}
        buttons={buttonsReportes}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

    </Styled>
  )
}

export default Reportes

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`