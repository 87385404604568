import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { colors, fonts } from '../../assets/styles/theme.ts';
import { RenderImgStyled } from '../Tables/TablesStyled.js';
import Lab from '../../assets/Icons/Buttons/Muestras/labs.svg';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
interface Props {
  label?: string;
  sub?: string;
  error?: boolean;
  active?: boolean;
  onClick?: () => void;
  backgroundColor?: string;
  icon?: boolean;
}

const BoxResult = ({ label, sub, error, active, onClick, backgroundColor, icon }: Props) => {

  return (
    <Styled error={error} active={active} onClick={onClick} backgroundColor={backgroundColor}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        {icon && <ScienceOutlinedIcon />}
        <Typography>{label ?? ""}</Typography>
      </Box>
      <Typography>{sub ?? ""}</Typography>
    </Styled>
  )
}

export default BoxResult

interface StyledProps {
  error?: boolean;
  active?: boolean;
  backgroundColor?: string;
}

const Styled = styled(Box) <StyledProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  border-radius: 10px;
  cursor: pointer;
  border: ${({ active, error }) => {
    if (active && !error) return `5px solid ${colors.turquoise}`;
    if (active && error) return `5px solid ${colors.turquoise}`;
    return 'none';
  }};
  width: 120px;
  background: ${({ backgroundColor, error }) =>
    backgroundColor ? backgroundColor : (error ? colors.error : colors.success)};
  & .MuiTypography-root {
    font-weight: 700;
    font-size: '20px';
    font-family: ${fonts.primary};
    color: ${colors.smokeWhite};
  }
`;