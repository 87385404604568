import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { colors, fonts } from '../../assets/styles/theme.ts';
import Input from '../Input/Input.jsx';

interface Props {
  value?: any;
  label?: string;
  sub?: string;
  error?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const CardNumber = ({ value, label, active, onClick }: Props) => {

  return (
    <Styled active={active} onClick={onClick}>
      {active && <Typography className="label">{label}</Typography>}
      <Input value={value} label={!active && label} className="input" />
    </Styled>
  )
}

export default CardNumber

interface StyledProps {
  error?: boolean;
  active?: boolean;
}

const Styled = styled(Box) <StyledProps>`
  .label{
    font-family:${fonts.primary};
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
  }
  .divInput{
    width: 100%;
    .MuiFormControl-root{
      width: 100% !important;
      border-radius: ${({ active }) => (active && "16px")};
    }
  }
  .input{
    background: ${({ active }) => (active ? colors.turquoise : "transparent")};
    
    .MuiInputBase-input{
      font-family:${fonts.primary};
      color: ${({ active }) => (active ? colors.white : colors.blueDark)};
      font-size: 36px;
      font-weight: 700;
      text-align: center;
    }
    fieldset{
      border:1px solid ${colors.turquoise};
      border-radius: ${({ active }) => (active && "16px")};
    }
  }

`;