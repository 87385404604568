import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";
import {
  getTiposLubricantesPaginated,
  deleteTipoLubricante,
  // getTipoLubricantesSearch,
} from "../../../../api/Configuracion";
import { CircularProgress } from "@mui/material";
import Modal2 from "../../../../components/Modal/Modal";
import Toast from "../../../../components/Toast/Toast";
import { set } from "react-hook-form";
const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const TipoLubricantes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [tipoLubricantes, setTipoLubricantes] = useState([]);
  const [tipoLubricanteId, setTipoLubricantesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchTiposLubricantesPaginated(paginaActual, orderBy, searchTerm, "primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchEstadosFinalesPaginated y searchTerm en la dependencia array

  // useEffect(() => {
  //   if (searchTerm === "") {
  //     getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm).then(
  //       (res) => {
  //         // alert(JSON.stringify(res.data))
  //         setPageCount(res.data.totalPages);
  //         setItemsCount(res.data.totalItems);
  //         setTipoLubricantes(
  //           res.data.items.map((res) => {
  //             return {
  //               id: res.id,
  //               codigo: res.codigo_tplub,
  //               descripcion: res.desc_tplub,
  //               habilitado: res.status,
  //             };
  //           })
  //         );
  //       }
  //     ).catch((err) =>
  //       console.error("Error al obtener datos de tipo lubricantes:", err)
  //     );
  //   } else {
  //     setPaginaActual(1);
  //     getTipoLubricantesSearch("", searchTerm).then(
  //       (res) => {
  //         // alert(JSON.stringify(res.data))
  //         setPageCount(res.data.totalPages);
  //         setItemsCount(res.data.totalItems);
  //         setTipoLubricantes(
  //           res.data.items.map((res) => {
  //             return {
  //               id: res.id,
  //               codigo: res.codigo_tplub,
  //               descripcion: res.desc_tplub,
  //               habilitado: res.status,
  //             };
  //           })
  //         );
  //       }
  //     ).catch((err) =>
  //       console.error("Error al obtener datos de lubricantes:", err)
  //     );;
  //   }
  // }, [searchTerm]);

  const fetchTiposLubricantesPaginated = async (paginaActual, orderBy, searchTerm, action) => {
    const res = await getTiposLubricantesPaginated(paginaActual, orderBy, searchTerm)
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
        if (res.data.items.length + 1 === 21) {
          setPaginaActual(res.data.totalPages);
        }
      } else if (action === "delete") {
          setTimeout(() => {
          setSuccess(false);
        }, 1000)

      } else {
        if (res.data.totalPages === 0) {
          setPaginaActual(1);
        } else if (res.data.totalPages < paginaActual) {
          setPaginaActual(res.data.totalPages);
        } else {
          setPaginaActual(paginaActual);
        }
      }

    setTipoLubricantes(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_tplub,
          descripcion: res.desc_tplub,
          habilitado: res.status,
        };
      })
    );
  }

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setTipoLubricantesId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteTipoLubricante(valueRow.id);
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };

  const handleDeleteTipoLubricante = (unidadId) => {
    setIsLoading(true);
    // setSuccess(false);
    setModalStatus2(false);
    deleteTipoLubricante(unidadId)
      .then(() => {
        setIsLoading(false);
        setTipoLubricantesId("");
        setSuccess(true);
        setMsjToast("Tipo de de Lubricante eliminado con exito");
        setKey(key + 1);
        
        const paginaActualTemp = tipoLubricantes.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);

        return fetchTiposLubricantesPaginated(
          paginaActualTemp, 
          orderBy, 
          searchTerm, 
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar el tipo de Lubricante.")
        setError(true)
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {

      valor = orderBy
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

   const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchTiposLubricantesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled >
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"tipoLubricantes"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchTiposLubricantesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          } else{
            fetchTiposLubricantesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm,
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
          setModalStatus(false);
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Tipo de Lubricantes"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nuevo tipo de lubricante"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={tipoLubricantes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        onClickSwitch={(value) => { handleOrder(value) }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteTipoLubricante(tipoLubricanteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default TipoLubricantes;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
   // height: 100vh;
  }
`;
