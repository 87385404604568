import axios from "./axios";

export const getOrdenesTrabajo = (page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getOrdenesTrabajoByCliente = (idCli, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/cliente/" +
      idCli +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
export const deleteOrdenTrabajo = (id) =>
  axios.delete(`/deleteOrdenTrabajo/${id}`);

export const getOrdenesTrabajoByPlanta = (idPla, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/planta/" +
      idPla +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getOrdenesTrabajoByEstado = (idEst, page, sort, search) =>
  axios.get(
    "/allOrdenTrabajo/estado/" +
      idEst +
      "?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

//https://be-dev.limsoil.com/allOrdenTrabajo/estado/:idEstado
//https://be-dev.limsoil.com/allOrdenTrabajo/c/:idPlanta
//https://be-dev.limsoil.com/allOrdenTrabajo/cliente/:idCliente

export const getOrdenesTrabajoAllFilters = (
  idCli,
  idPla,
  idEst,
  page,
  sort,
  search
) =>
  axios.get(
    "/allOrdenTrabajo?idCliente=" +
      idCli +
      "&idPlanta=" +
      idPla +
      "&idEstadoOT=" +
      idEst +
      "&page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

//https://be-dev.limsoil.com/allOrdenTrabajo?idCliente=15&idPlanta=47&idEstadoOT=1&page=1&pageSize=10

export const getEstadoOrdenesOptions = () =>
  axios.get("/allEstadoOrdenesTrabajoOptions");

export const getEstadosOrdenesTrabajo = () =>
  axios.get("/allEstadoOrdenesTrabajoWithoutPagination");

export const getMuestrasPaginated = (page, sort, search) =>
  axios.get(
    "/allOrdenesTrabajo?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const createOrdenTrabajo = (orden) =>
  axios.post("/createOrdenTrabajo", orden);
export const updateOrdenTrabajo = (orden) =>
  axios.put(`/updateOrdenTrabajo`, orden);

export const avanzarOrdenTrabajo = (id) => axios.put(`/avanzarEstado/${id}`);

export const getAllOrdenTrabajoLog = (ordenId) =>
  axios.get(`/allOrdenTrabajoLog/orden/${ordenId}`);

// https://be-dev.limsoil.com/allOrdenByClienteIdOptions/:idCliente
// https://be-dev.limsoil.com/allPuntoTomasByClienteIdOptions/:idCliente
// https://be-dev.limsoil.com/allConjuntoAnalisisOptions
// https://be-dev.limsoil.com/allLubricanteOptions

export const updateResultadosValor = (idMuestra, resultados) =>
  axios.put(`updateResultadosValor/${idMuestra}`, resultados);

export const getResultadoOptionsByAnalisis = (idAnalisis, idOrden) =>
  axios.get(`allResultadoOptionsByAnalisis/analisis/${idAnalisis}/orden/${idOrden}`);

export const getAllAnalisisOptionsByOrden = (idOrden) =>
  axios.get(`/allAnalisisOptionsByOrden/${idOrden}`);

export const getAllOrdenByCliente = (idCliente) =>
  axios.get(`/allOrdenByClienteIdOptions/${idCliente}`);
export const getAllOrdenByPlantaOptions = (id) =>
  axios.get(`/allOrdenByPlantaIdOptions/${id}`);

export const getAllPuntoTomasByCliente = (idCliente) =>
  axios.get(`/allPuntoTomasByClienteIdOptions/${idCliente}`);

export const getAllConjuntoAnalisis = () =>
  axios.get(`/allConjuntoAnalisisOptions`);

export const getAnalisisOptions = () =>
  axios.get("allAnalisisOptions");

export const getAllLubricante = () => axios.get(`/allLubricanteOptions`);

export const getMuestras = (page, sort, search) =>
  axios.get(
    "/allMuestra?page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );

export const getMuestrasAllFilters = (
  idCli,
  idPla,
  idEst,
  idEqui,
  page,
  sort,
  search
) =>
  axios.get(
    "/allMuestra?idCliente=" +
      idCli +
      "&idPlanta=" +
      idPla +
      "&idOrdenTrabajo=" +
      idEst +
      "&idEquipo=" +
      idEqui +
      "&page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search
  );
  export const getMuestrasNewFilters = (
  idCli, idPla, idEst, idEqui, page, sort, search, sinDiagnostico, sinEstado, 
  sinFechaAnalisis, sinImagenes, sinResultados, sinSugerencias
) =>
  axios.get(
    "/allMuestra?idCliente=" +
      idCli +
      "&idPlanta=" +
      idPla +
      "&idOrdenTrabajo=" +
      idEst +
      "&idEquipo=" +
      idEqui +
      "&page=" +
      page +
      "&pageSize=20&sortBy=" +
      sort +
      "&searchTerm=" +
      search+
      "&sinDiagnostico=" +
      sinDiagnostico+
      "&sinEstado=" +
      sinEstado+
      "&sinFechaAnalisis=" +
      sinFechaAnalisis+
      "&sinImagenes=" +
      sinImagenes+
      "&sinResultados=" +
      sinResultados+
      "&sinSugerencias=" +
      sinSugerencias
      
  );

  //https://be-dev.limsoil.com/allMuestra?searchTerm=&sortBy=
  //codigo:true&page=1&pageSize=10&sinEstado=true&sinFechaAnalisis=true
  //&sinImagenes=true&sinResultados=true&sinSugerencias=true

export const createMuestra = (muestra) => axios.post("/createMuestra", muestra);

export const updateDiagnosticoByMuestraId = (diagnostico) => axios.put("/updateDiagnosticoByMuestraId", diagnostico);

export const updateResultadoByMuestraId = (muestraId, resultados) => axios.put("/updateResultadoByMuestra/" + muestraId, resultados)

export const createResultadoByMuestraId = (muestraId, resultado) => axios.post("createResultadoByMuestra/" + muestraId, resultado)

export const updateMuestra = (muestra) => axios.put(`/updateMuestra`, muestra);

export const getAllOrdenByClienteOptions = (id) =>
  axios.get(`/allOrdenByClienteIdOptions/${id}`);

export const getAllEquipoByClienteOptions = (id) =>
  axios.get(`/allEquipoOptions/cliente/${id}`);

//allEquipoOptions/cliente/:clienteId

export const deleteMuestra = (id) => axios.delete(`/deleteMuestra/${id}`);
//https://be-dev.limsoil.com/deleteMuestra/:id


export const deleteFile = (idFile, idMuestra) => axios.delete(`/deleteArchivoByMuestra/` + idFile + `/` + idMuestra);

export const getMuestraContadores = (ordenId) => axios.get(`/muestraContadores/${ordenId}`);


