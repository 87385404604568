import React, { useState, useEffect } from "react";
import { Styled, StyledTypography } from "./TemplateEmailsStyled.js";
import { Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import { RenderImgStyled } from "../../../Task/Items/SuperAdminStyled.js";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable.js";
import {
  getTemplateEmailsPaginated,
  getTemplateEmailsSearch,
  deleteTemplateEmails,
} from "../../../../api/Configuracion.js";
import More from "../../../../assets/Icons/Buttons/More.svg";
import CustomButton from "../../../../components/Button/CustomButton.tsx";
import Input from "../../../../components/Input/Input.jsx";
import Tables from "../../../../components/Tables/Tables.jsx";
import Modal from "../../../../components/Modal/Modal.jsx";
import Modal2 from "../../../../components/Modal/Modal.jsx";
import Toast from "../../../../components/Toast/Toast.jsx";
import { CircularProgress } from "@mui/material";
const columnsDefaultCfg = [
  { id: "id", label: "Id" },
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "asunto", label: "Asunto" },
  { id: "cuerpo", label: "Cuerpo" },
  { id: "buttons" },
];

const TemplateEmails = () => {
  const [datosModal, setDatosModal] = useState("");
  const [statusModal, setModalStatus] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [paginaActual, setPaginaActual] = useState(1);
  const [templateEmails, setTemplateEmails] = useState([]);
  const [templateEmailsId, setTemplateEmailsId] = useState([]);
  const [msjToast, setMsjToast] = useState("");
  const [success, setSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchTemplateEmailsPaginated(paginaActual, orderBy, searchTerm, "primera");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchTemplateEmailsPaginated y searchTerm en la dependencia array

  const handleClickButtonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      setModalStatus(true);
    } else {
      setDatosModal("");
      setTemplateEmailsId(valueRow.id);
      setModalStatus2(true);
    }
    if (value === "Crear") {
      setDatosModal("");
      setModalStatus(true);
    }
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }
    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleDeleteCausasDeFalla = (causasDeFallasId) => {
    setIsLoading(true);
    setModalStatus2(false);
    deleteTemplateEmails(causasDeFallasId)
      .then(() => {
        setIsLoading(false);
        setTemplateEmailsId("");
        setSuccess(true);
        setMsjToast("Template de emails eliminado con exito.");
        setKey(key + 1);

        const paginaActualTemp = templateEmails.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);
        
        return fetchTemplateEmailsPaginated(
          paginaActualTemp,
          orderBy,
          searchTerm,
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setMsjToast("Error al eliminar template de emails.");
        setError(true);
      });
  };

  const fetchTemplateEmailsPaginated = async (
    pagina,
    order,
    search,
    action
  ) => {
    const res = await getTemplateEmailsPaginated(pagina, order, search);

    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setTimeout(() => {
        setSuccess(false);
      }, 1000)
    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setTemplateEmails(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_t_em,
          descripcion: res.desc_t_em,
          asunto: res.subject_t_em,
          cuerpo: res.body_t_em,
        };
      })
    );
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchTemplateEmailsPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading && (
        <CircularProgress
          size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999",
          }}
        />
      )}
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"templateEmails"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setKey(key + 1);
            setSuccess(true);
            setMsjToast(msj);
            fetchTemplateEmailsPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            // setKey(key+1);
            setError(true);
            setMsjToast(msj);
          } else if (status === "cancel") {
            fetchTemplateEmailsPaginated(
              paginaActual,
              orderBy,
              searchTerm,
              msj.includes("cread") ? "new" : ""
            );
          }
          setModalStatus(false);
        }}
      />

      <StyledTypography>Templates Emails</StyledTypography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0px",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Input
            inputProp={true}
            positionIcon={"start"}
            placeholder={"Buscar por código, descripción, etc"}
            icon={<Search />}
            size={"small"}
            onChange={(e) => {
              handleBuscador(e.target.value);
            }}
            value={searchTerm}
            search
            isClearable={() => {
              handleBuscador("");
            }}
            className="inputComponent"
            style={{ borderRadius: "10px", paddingLeft: "0px" }}
          />
        </Box>
        <CustomButton
          label={"Nuevo Template Emails"}
          startIcon={<RenderImgStyled img={More} />}
          variant={"contained"}
          className="buttonHeaderTable"
          onClick={() => {
            handleClickButtonTable("crear");
          }}
        />
      </Box>

      <Tables
        typeTable={"prop"}
        columns={columnsDefaultCfg}
        rows={templateEmails}
        cRows={20}
        totalRows={itemsCount}
        actualpage={paginaActual}
        pagination={true}
        tableColor={false}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButtonTable(value, valuesRow);
        }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteCausasDeFalla(templateEmailsId);
        }}
      />
    </Styled>
  );
};

export default TemplateEmails;
