import React, { useState, useEffect } from "react";
import Tables from "../../../../components/Tables/Tables";
import HeaderTable from "../../../../components/HeaderTable/HeaderTable";
import Modal from "../../../../components/Modal/Modal";
import {
  getLubricantesPaginated,
  deleteLubricante,
  // getLubricantesSearch,
} from "../../../../api/Configuracion";
import Toast from "../../../../components/Toast/Toast";
import Modal2 from "../../../../components/Modal/Modal";
import { CircularProgress } from "@mui/material";
import { buttonsDefault } from "../../../../components/ButtonsTable/ButtonsTable";
import styled from "styled-components";

const columns = [
  { id: "codigo", label: "Código" },
  { id: "descripcion", label: "Descripción" },
  { id: "tipo", label: "Tipo" },
  { id: "habilitado", label: "Habilitado" },
  { id: "buttons" },
];

const Lubricantes = () => {
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [lubricantes, setLubricantes] = useState([]);
  const [lubricanteId, setLubricantesId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [orderBy, setOrderBy] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchLubricantesPaginated(paginaActual, orderBy, searchTerm, "primera");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginaActual, orderBy]); // Ignorando fetchLubricantesPaginated y searchTerm en la dependencia array

  const fetchLubricantesPaginated = async (pagina, order, search,action) => {

    const res = await getLubricantesPaginated(pagina, order, search);
    setPageCount(res.data.totalPages);
    setItemsCount(res.data.totalItems);

    if (action === "new") {
      if (res.data.items.length + 1 === 21) {
        setPaginaActual(res.data.totalPages);
      }
    } else if (action === "delete") {
      setTimeout(() => {
        setSuccess(false);
      }, 1000)

    } else {
      if (res.data.totalPages === 0) {
        setPaginaActual(1);
      } else if (res.data.totalPages < paginaActual) {
        setPaginaActual(res.data.totalPages);
      } else {
        setPaginaActual(paginaActual);
      }
    }

    setLubricantes(
      res.data.items.map((res) => {
        return {
          id: res.id,
          codigo: res.codigo_lub,
          descripcion: res.desc_lub,
          habilitado: res.status,
          tipo: res.tipo_lub_id.desc_tplub,
        }
      })
    );
  };

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      setModalStatus(true);
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    } else {
      setLubricantesId(valueRow.id);
      setModalStatus2(true);
      // handleDeleteLubricante(valueRow.id)
    }
  };

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  };
  
  const handleDeleteLubricante = (unidadId) => {
    setIsLoading(true);
    // setSuccess(false);
    setModalStatus2(false);
    deleteLubricante(unidadId)
      .then(() => {
        setIsLoading(false);
        setLubricantesId("");
        setSuccess(true);
        setMsjToast("Lubricante eliminado con exito.");
        setKey(key + 1);
        
        const paginaActualTemp = lubricantes.length === 1 && paginaActual > 1 ? paginaActual - 1 : paginaActual;
        setPaginaActual(paginaActualTemp);
        
        return fetchLubricantesPaginated(
          paginaActualTemp, 
          orderBy, 
          searchTerm, 
          "delete"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        setMsjToast("Error al eliminar El Lubricante");
        setError(true);
      });
  };

  const handleOrder = (value) => {
    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== value.length) {
      valor = value;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleBuscador = (value) => {
    setSearchTerm(value);
    fetchLubricantesPaginated(paginaActual, orderBy, value, "search");
  };

  return (
    <Styled>
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast key={key} title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          key={key}
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"lubricantes"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchLubricantesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm, 
              msj.includes("cread") ? "new" : ""
            );
          } else if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }else{
            fetchLubricantesPaginated(
              paginaActual, 
              orderBy, 
              searchTerm, 
              "ver"
            );
          }
          setTimeout(() => {
            setSuccess(false);
            setError(false); 
            setMsjToast(''); 
          }, 1000)
          setModalStatus(false);
        }}
      />

      <HeaderTable
        styledHead="cfg"
        cfg
        title="Lubricantes"
        value={searchTerm}
        valueBuscador={(value) => handleBuscador(value)}
        isClearable={() => { handleBuscador("") }}
        labelButton={"Nuevo Lubricante"}
        onClickButton={() => {
          handleClickButtonHeader();
        }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        rows={lubricantes}
        cRows={20}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => {
          handleOrder(value);
        }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        // valueBuscador={valueBuscador}
        buttons={buttonsDefault}
        onClickButtonTable={(value, valuesRow) => {
          handleClickButonTable(value, valuesRow);
        }}
      />
      
      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          setModalStatus2(false);
          handleDeleteLubricante(lubricanteId);
        }}
      ></Modal2>
    </Styled>
  );
};

export default Lubricantes;

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables {
    //height: 100vh;
  }
`;
