import React, { useState } from 'react';
import { StyledModalcomponentReportesModalGraph } from '../../Styled.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Print from "../../../../assets/Icons/Buttons/Muestras/Print.svg";
import { RenderImgStyled } from '../../../Tables/TablesStyled.js';
import BarChartComponent from '../../../BarChart/BarChartComponent.jsx';
import { useMediaQuery } from "@mui/material";

const ReportesGraphModal = (props) => {
  const titulo = () => {
    const titulo = "Generación de Reportes"
    return titulo
  }
  const renderButtons = () => {
    return (
      <div className="divButtons" style={{}}>
        <CustomButton
          onClick={() => {

            if (props.onClick) {
              props.onClick();
            }
          }}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
          size="small"
        />

        <CustomButton
          label={"Imprimir"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { alert() }}
          size="small"
          startIcon={
            <RenderImgStyled img={Print}
              style={{ padding: "5px" }} />
          }
        // disabled={!formCompleto}
        />

      </div>
    );
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const renderBarChart = () => {
    let defaultDataReportes;
    let footerLabel;
    let styled;
    let yDecimal;
    switch (props.datos.form.tipoReporte) {
      case "estado":
        defaultDataReportes = [
          { label: "Normal", data: [10, 30], color: "#82fc00" },
          { label: "Precaucion", data: [40, 50], color: "#fceb00" },
          { label: "Peligro", data: [50, 70], color: "#fc0000" },
        ];
        footerLabel = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        yDecimal = false;
        styled = "reportesEstado"
        break;
      case "analisis":
        defaultDataReportes = [
          { label: "Conjunto Básico", data: [11.50], color: "#002efc" },
          { label: "Conjunto Completo", data: [0, 12.50], color: "#fc6d00" }
        ];
        footerLabel = ["01 - Conjunto básico", "02 - Conjunto completo"]
        styled = "reportesAnalisis"
        yDecimal = true;
        break;
      case "diagnostico":
        defaultDataReportes = [
          { label: "Pendiente", data: [5.75], color: "#fc0000" },
          { label: "Publicada", data: [3, 4, 5], color: "#2efc00" },
        ];
        footerLabel = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        styled = "reportesDiagnostico"
        yDecimal = false;
        break;
      default:
        return null;
    }

    return (
      <BarChartComponent
        series={defaultDataReportes}
        footerLabel={footerLabel}
        width={!isMobile ? 1500 : 1000}
        height={!isMobile ? 700 : 500}
        styled={styled}
        yDecimal={yDecimal}
      />
    );
  };
  return (
    <StyledModalcomponentReportesModalGraph sx={{ height: "90%", overflowY: "auto" }}>
      <div className="reportesGraphModal">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>

        <div className="divHeader">
          <div className="aCol">
            <p className="label">Fecha de Reporte:<span className="span"></span></p>
            <p className="label">Autor:<span className="span"></span></p>
          </div>
          <div className="bCol">
            <p className="label">Tipo de Reporte:</p>
            <div className="divTipo"><p className="label">{props.datos.tipoReporte.label ?? ""}</p></div>
          </div>

        </div>
        <div className="divBody">
          <div className="divLabelBody">
            <div className="acolLabel">
              <p className="label">Cliente:<span className="span"></span></p>
              <p className="label">Planta:<span className="span"></span></p>
            </div>
            <div className="bcolLabel">
              <p className="label">Equipo:<span className="span"></span></p>
              <p className="label">Período:<span className="span"></span></p>
            </div>
          </div>

          <div className="ccolButtons">
            <p className="label">NOTA: se toma como inicio la Fecha de notificación de la muestra y como fin la Fecha de Publicación</p>
            {renderButtons()}
          </div>
        </div>

        {renderBarChart()}
      </div>

    </StyledModalcomponentReportesModalGraph>
  )
}

export default ReportesGraphModal;