import React from 'react';
import styled from 'styled-components';
import CustomButton from '../../../../../Button/CustomButton.tsx';
import CloseIcon from '@mui/icons-material/Close';

const Card = (props) => {
  const diagnostico = props.datos;
  //console.log(diagnostico);
  return (
    <CardStyled>
      {
          <>
            <div className="divHeader">
              <div className="divNMuestra">
                <p className="label">Nro de muestra</p>
                <div className="divBody"><p className="labelBody">{diagnostico.id}</p></div>
              </div>
              <div className="divCP">
                <p className="label">Cliente / Planta</p>
                <div className="divBody"><p className="labelBody">{diagnostico.cliente.desc_cli + " / " + diagnostico.planta.description}</p></div>
              </div>
            </div>
            <div className="divBody">
              <div className="divDatos">
                <ul>
                  <li><span className="span">Equipo:</span> <span className="spann">{diagnostico.equipo.description}</span></li>
                  <li><span className="span">Punto de Toma:</span> <span className="spann">{diagnostico.punto_toma.description}</span></li>
                  <li><span className="span">Lubricante:</span> <span className="spann">{diagnostico.lubricante.description}</span></li>
                  <li><span className="span">Extracción:</span> <span className="spann">{diagnostico.responsable_toma_mue + " - " + diagnostico.fecha_toma_mue.slice(0, 10)}</span></li>
                </ul>
              </div>
            </div>
          </>
      }
    </CardStyled>
  );
};

export default Card

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .divHeader {
    display: flex;
    gap: 10px;
  }
  .divCP {
    width: 75%;
  }
  .divNMuestra,
  .divCP {
    display: flex;
    flex-direction: column;
    .label {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      color: #545454;
      font-weight: 400;
      margin: 5px;
    }

    .divBody {
      background: #dbe5e8;
      border-radius: 10px;
      border: 1px solid #afccd5;
      padding: 15px;
      .labelBody {
        font-family: "Work Sans", sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: #163c5b;
        margin: 0px;
      }
    }
  }
  .divBody {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .divDatos {
      display: flex;
      flex-direction: column;
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
          margin-bottom: 10px;
          .span,
          .spann {
            font-family: "Inter", sans-serif;
          }
          .span {
            color: #545454;
            display: inline-block;
            width: 150px;
            font-size: 12px;
          }
          .spann {
            color: #163c5b;
            font-weight: bold;
            font-size: 13px;
          }
        }
      }
    }
    .divButtons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .btnEstado {
        width: 173px;
        background-color: #66bbd3;
      }
      .btnClose {
        margin-left: auto;
      }
      .title {
        margin: 0px;
        font-family: "Inter", sans-serif;
        color: #545454;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
`;